import 'Vendor/owlcarousel/owl.carousel.min';
import { speed } from 'Lib/speed/speed';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { Popup } from 'Lib/popup/popup';
import { copytext } from 'Lib/copy-text/copy-text';

const cSliderOn = 'owl-carousel';

function setSliderSalesBook() {
    $('.sales-book__box_slider').trigger('destroy.owl.carousel')
        .removeClass(cSliderOn)
        .addClass(cSliderOn)
        .owlCarousel({
            smartSpeed: speed * 2,
            dots: false,
            nav: true,
            items: 1,
            slideBy: 1,
            margin: 0,
            navClass: ['sales-book__prev arrow arrow_no-bg arrow_prev', 'sales-book__next arrow arrow_no-bg arrow_next'],
            navText: ['', ''],
        });
}

if (getW() > eksmoBP.smMin) {
    setSliderSalesBook();
} else {
    const elSalesBookPopup = document.querySelector('.sales-book__popup');
    const popup = new Popup('popup', elSalesBookPopup);

    document.querySelectorAll('.sales-book__alert').forEach((item) => {
        item.addEventListener('click', () => {
            elSalesBookPopup.querySelector('.popup__modal-body').textContent = item.getAttribute('data-detail');
            popup.open(true);
        });
    });
}

$('.sales-book__code-copy-icon-box').on('click', function () {
    copytext($(this).find('.sales-book__code-copy-icon-box-promocode'));
});

$('.sales-book__alert').on('mouseenter mouseleave', function () {
    $(this).closest('.sales-book').toggleClass('sales-book_active');
});

$('body').on('init.fastViewBook', setSliderSalesBook);