import { isTouch } from 'Lib/istouch/istouch';
import { speed } from 'Lib/speed/speed';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');
const sForCopy = '.copy';
const cForCopyActive = 'copy_active';
const sForCopyVal = '.copy__val';
const cDrop = 'copy__tip';
const sDrop = `.${cDrop}`;
const showSpeed = speed * 10;
const defText1 = 'Копировать';
const defText2 = 'Скопировано!';
const htmlDefault = `
    <div class="${cDrop}" style="display: block;">
        <div class="tip tip_min tip_not-row tip_black">
            <div class="tip__style">
                <span class="copy__text1">${defText1}</span>
                <span class="copy__text2">${defText2}</span>
            </div>
        </div>
    </div>
`;
let htmlTip = htmlDefault;

function replaceText($th) {
    if (!$th.find(sDrop).length) {
        const newText1 = $th.attr('data-copy-text1');
        const newText2 = $th.attr('data-copy-text2');

        htmlTip = htmlDefault;

        if (isNoEmptyString(newText1)) {
            htmlTip = htmlTip.replace(defText1, newText1);
        }

        if (isNoEmptyString(newText2)) {
            htmlTip = htmlTip.replace(defText2, newText2);
        }

        $th.append(htmlTip);
    }
}

if (!isTouch()) {
    $body.on('mouseenter', sForCopy, function () {
        const $this = $(this);

        replaceText($this);
        $this.find(sDrop).show();
    });

    $body.on('mouseleave', sForCopy, function () {
        const $this = $(this);

        $this.find(sDrop).hide();
        $this.removeClass(cForCopyActive);
    });
}

$body.on('click focus', sForCopy, function (e) {
    const $thatParent = $(this);
    const $val = $thatParent.find(sForCopyVal).first();
    const href = $thatParent.attr('href');

    function setSuccess() {
        try {
            if (document.execCommand('copy')) {
                if (!$thatParent.find(sDrop).length) {
                    $thatParent.append(htmlTip);
                }

                $thatParent.find(sDrop).show();
                $thatParent.addClass(cForCopyActive);
                $thatParent.find('.copy__icon').removeClass('copy__icon_active');
                $thatParent.find('.copy__icon_success').addClass('copy__icon_active');

                setTimeout(() => {
                    $thatParent.find(sDrop).hide();
                    $thatParent.find('.copy__icon').addClass('copy__icon_active');
                    $thatParent.find('.copy__icon_success').removeClass('copy__icon_active');
                }, showSpeed);
            }
        } catch (err) {
            console.log(`Error: ${err}`);
        }
    }

    replaceText($thatParent);

    if ($val.length) {
        const elemVal = $val[0];
        const selection = window.getSelection();
        const range = document.createRange();

        range.selectNodeContents(elemVal);
        selection.removeAllRanges();
        selection.addRange(range);
        setSuccess();

        // selection.removeAllRanges(); // снять выделение
    } else if (isNoEmptyString(href)) {
        const $temp = $("<input style='font-size:10px'>");

        e.preventDefault();
        $(this).before($temp);
        $temp.val(href).focus().select();
        document.execCommand('copy');
        $temp.remove();
        setSuccess();
    }
});