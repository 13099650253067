import 'COMMON';
import { oLikeDislike } from 'Lib/like-dislike/like-dislike';
import { oReaded } from 'Lib/readed/readed';
import 'Vendor/polifill-object-fit-images/polifill-object-fit-images';
import { svgSpriteLoader } from 'Lib/svg-sprite-loader/svg-sprite-loader';
import { oLike } from 'Lib/like/like';
import 'Lib/js-anchor-link/js-anchor-link';
import 'Bem/journal/sn-share/sn-share';
import 'Bem/site/meetings/meetings';
import 'Bem/site/spoiler/spoiler';
import 'Bem/journal/text-insert/text-insert';
import 'Bem/journal/gallery/gallery';
import 'Bem/site/collections-slider/collections-slider';
import 'Bem/site/book/book';
import 'Bem/site/switchbox/switchbox';
import 'Bem/journal/text-insert-card/text-insert-card';
import 'Bem/site/checkbox/checkbox';
import 'Bem/journal/article-t/article-t';
import 'Bem/journal/article-share/article-share';
import 'Bem/site/author-page/author-page';
import 'Bem/journal/fixed-menu/fixed-menu';
import 'Bem/site/radio/radio';
import 'Bem/site/publications-slider/publications-slider';
import 'Bem/site/form-bg/form-bg';
import 'Bem/site/instagram-list/instagram-list';
import 'Bem/site/photo-masonry/photo-masonry';
import 'Bem/site/tabs/tabs';
import 'Bem/site/book-preview/book-preview';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { initPostload as booksSliderInitPostload } from 'Bem/site/books-slider/books-slider';
import 'Bem/site/cycles-books/cycles-books';
import 'Bem/site/authors-slider/authors-slider';
import { Reactions } from 'Lib/reactions/reactions';
import 'Bem/site/popup-subscribe/popup-subscribe';
import 'Bem/site/finder/finder';
import 'Bem/journal/text-insert-min/text-insert-min';
import 'Bem/journal/video/video';
import 'Bem/site/sales-book/sales-book';
import 'Bem/site/copy/copy';
import 'Bem/site/menu-left/menu-left';
import 'Bem/site/journal-articles/journal-articles';

let reactions = new Reactions(document.querySelectorAll('.reactions'));

reactions.init();

svgSpriteLoader('/site/author/svg/sprite.svg?v=20180320');
oLike.init('.js-book-like, .js-like, .js-article-like');
oReaded.init('.js-readed');

const $body = $('body');

$body.on('eksmoPagenavAjax', (e) => {
    oLike.init('.js-book-like, .js-like, .js-article-like');
    oReaded.init('.js-readed');
    oLikeDislike.init('.js-like-dislike');
    booksSliderInitPostload($(e.target).find('.books-slider_post-load'));
    reactions = new Reactions(document.querySelectorAll('.reactions'));
    reactions.init();
});

oLikeDislike.init('.js-like-dislike');

// Фильтр книг
$body.on('eksmoSetMMenu', '.js-m-menu-author-menu', function () {
    const $thisMMenu = $(this);
    const sRadio = '.js-author-menu-radio';
    const sMenuBooksSubmit = '.js-author-menu-submit';
    const cBtnDisabled = 'btn_disabled';

    function setUrl() {
        const $submitLink = $thisMMenu.find(sMenuBooksSubmit).first();

        if ($submitLink.length) {
            $thisMMenu.find(sRadio).each(function () {
                const $this = $(this);

                if ($this.find('input:checked').length) {
                    const dataHref = $this.attr('data-href');

                    if (isNoEmptyString(dataHref)) {
                        $submitLink.removeClass(cBtnDisabled);
                        $submitLink.attr('data-href', dataHref);
                    }
                }
            });
        }
    }

    $thisMMenu.on('eksmoChangeRadio', sRadio, setUrl);

    $thisMMenu.on('eksmoHideMMenu', function () {
        $(this).find(sMenuBooksSubmit).addClass(cBtnDisabled);
    });

    $thisMMenu.on('click', sMenuBooksSubmit, function () {
        if ($(this).hasClass('btn_disabled')) {
            return false;
        }

        $thisMMenu.trigger('eksmoMMenuClose');

        return true;
    });
});

// Фильтр циклов
$('.js-m-menu-filter-cycles').on('eksmoSetMMenu', function () {
    const $this = $(this);
    const sCheckbox = '.js-filter-cycles-checkbox';
    const sRadioPriceLabels = '.js-filter-cycles-radio-price';
    const $submitLink = $this.find('.js-filter-cycles-submit').first();
    const oldHref = $submitLink.attr('href') || '';

    function setUrl() {
        const arrUrl = [];

        $this.find(sCheckbox).each(function () {
            const $thisCheckbox = $(this);
            const value = $thisCheckbox.find('input:checked').length ? $thisCheckbox.attr('data-filter-cycles-y') : $thisCheckbox.attr('data-filter-cycles-n');

            if (isNoEmptyString(value)) {
                arrUrl.push(value);
            }
        });

        $this.find(sRadioPriceLabels).each(function () {
            const $thisLabel = $(this);

            if ($thisLabel.find('input:checked').length) {
                const data = $thisLabel.attr('data-filter-cycles');

                if (isNoEmptyString(data)) {
                    arrUrl.push(data);
                }
            }
        });

        $submitLink.removeClass('btn_disabled');
        $submitLink.attr('href', oldHref + ((oldHref.indexOf('?') + 1) ? '&' : '?') + arrUrl.join('&'));
    }

    if ($submitLink.length) {
        $this.on('eksmoChangeCheckbox', sCheckbox, setUrl);
        $this.on('eksmoChangeRadio', sRadioPriceLabels, setUrl);
    }
});