import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
// import 'Lib/fb-sdk/fb-sdk';

const $parent = $('.sn-share');
const sLink = '.sn-share__link';

$parent.each(function () {
    const href = $(this).data('href');

    if (href) {
        const $this = $(this);
        const $link = $this.find(sLink);

        setQTYShared($this);

        $link.on('click', function () {
            const key = $(this).data('key');

            setQTYShared($this, key);
        });
    }
});

$(document).on('click', sLink, function (e) {
    const $this = $(this);
    let href = $this.attr('href');
    const key = $this.data('key');

    if (typeof href === 'string' && typeof key === 'string') {
        if (href.length && key.length) {
            e.preventDefault();
            eventSender.eventSnShare(key);

            if (key === 'fb') {
                /* href = $this.attr('data-href');

                if (typeof href !== 'undefined') {
                    window.FB.ui({
                        method: 'share',
                        mobile_iframe: true,
                        href: decodeURIComponent(href),
                    }, () => {});
                } */
            } else {
                let wwin = getW();

                if (wwin > 560) {
                    wwin = 560;
                }

                const mLeft = (window.screen.availWidth / 2) - (wwin / 2);

                window.open(href, 'MsgWindow', `width=${wwin}, height=400, left=${mLeft}, top=50`);
            }
        }
    }
});

function setQTYShared($p, keyAdd) {
    const $linkFB = $p.find('.sn-share__link_fb');
    const $linkVK = $p.find('.sn-share__link_vk');
    const $linkTW = $p.find('.sn-share__link_tw');
    const $linkOK = $p.find('.sn-share__link_ok');
    const sTitle = '.sn-share__text_title';
    const cTitleHidden = 'sn-share__text_hidden';
    const sQTY = '.sn-share__text_qty';
    const htmlFirstPathElQTY = '<div class="sn-share__text sn-share__text_qty">';
    const htmlLastPathElQTY = '</div>';
    const cSimple = 'sn-share_simple';
    const oPost = {};
    const href = $p.data('href');

    if (keyAdd) {
        oPost.add = keyAdd;
    }

    $.post(href, oPost, (data) => {
        if (!$p.hasClass(cSimple)) {
            const oJSON = $.parseJSON(data);
            const fb = parseInt(oJSON.fb);
            const vk = parseInt(oJSON.vk);
            const tw = parseInt(oJSON.tw);
            const ok = parseInt(oJSON.ok);

            if (fb) {
                if ($linkFB.find(sQTY).length) {
                    $linkFB.find(sQTY).html(fb);
                } else {
                    $linkFB.append(htmlFirstPathElQTY + fb + htmlLastPathElQTY);
                }

                $linkFB.find(sTitle).addClass(cTitleHidden);
            }

            if (vk) {
                if ($linkVK.find(sQTY).length) {
                    $linkVK.find(sQTY).html(vk);
                } else {
                    $linkVK.append(htmlFirstPathElQTY + vk + htmlLastPathElQTY);
                }

                $linkVK.find(sTitle).addClass(cTitleHidden);
            }

            if (tw) {
                if ($linkTW.find(sQTY).length) {
                    $linkTW.find(sQTY).html(tw);
                } else {
                    $linkTW.append(htmlFirstPathElQTY + tw + htmlLastPathElQTY);
                }

                $linkTW.find(sTitle).addClass(cTitleHidden);
            }

            if (ok) {
                if ($linkOK.find(sQTY).length) {
                    $linkOK.find(sQTY).html(ok);
                } else {
                    $linkOK.append(htmlFirstPathElQTY + ok + htmlLastPathElQTY);
                }

                $linkOK.find(sTitle).addClass(cTitleHidden);
            }
        }
    });
}