import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';

const $pagenavAjax = $('.pagenav-ajax');
const $publications = $('.publications-slider');
const sPublicationsIMGForLoad = '.publications-slider__bg';

function loadPics($els) {
    onScreen($els, loadSrcOrBg);
}

loadPics($publications.find(sPublicationsIMGForLoad));

$pagenavAjax.on('eksmoPagenavAjax', (e) => {
    loadPics($(e.target).find(sPublicationsIMGForLoad));
});