import Masonry from 'masonry-layout';
import { speed } from 'Lib/speed/speed';
import { eksmoBP } from 'Lib/bp/bp';
import { getW } from 'Lib/w/w';
import { onBpChange } from 'Lib/on-bp-change/on-bp-change';

const namePropInstance = 'masonry-instance';
const sCont = '.photo-masonry__cont';
const sSlider = '.photo-masonry';
const sMSlider = '.photo-masonry_m-slider';

function init($slider) {
    const $dSliderCont = $slider.not(sMSlider).find(sCont);
    const $mSliderCont = $slider.filter(sMSlider).find(sCont);

    initMasonry($dSliderCont);

    if ($mSliderCont.length) {
        if (getW() > eksmoBP.xsMax) {
            initMasonry($mSliderCont);
        }

        onBpChange(eksmoBP.xsMax, (isLess) => {
            const $lists = $mSliderCont;

            if (isLess) {
                $lists.each(function () {
                    $(this)[0][namePropInstance].destroy();
                });
            } else {
                initMasonry($lists);
            }
        });
    }
}

function initMasonry($lists) {
    if ($lists instanceof jQuery && $lists.length) {
        $lists.each(function () {
            const $list = $(this);
            const $img = $list.find('.photo-masonry__pic');
            const instanceMasonry = new Masonry($list[0], {
                transitionDuration: 0,
                stagger: 0,
            });

            function setListenerImgLoad($images) {
                $images.on('eksmoImgLoadSrcOrBg', () => {
                    reloadMasonry(instanceMasonry);
                });

                $images.each(function () {
                    const image = new Image();

                    image.onload = function () {
                        reloadMasonry(instanceMasonry);
                    };

                    image.src = $(this)[0].src;
                });
            }

            $list[0][namePropInstance] = instanceMasonry;
            setListenerImgLoad($img);
        });
    }
}

function reloadMasonry(instance) {
    setTimeout(() => {
        instance.reloadItems();
        instance.layout();
    }, speed + 10);
}

init($(sSlider));

$('body').on('init.photoMasonry.eksmo', sSlider, function () {
    init($(this));
});