import { speed } from 'Lib/speed/speed';
import { getUrlByInputs } from 'Lib/get-url-by-inputs/get-url-by-inputs';
import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { eventSender } from 'Lib/event-sender/event-sender';
import './include/previous-filters'; // Ранее применённые фильтры каталога (EKSMORU-1945)

const $menu = $('.menu-left');
const sFilterInput = '.menu-left__filter-input';
const $filterControl = $('.menu-left__control').first();
const cFilterControlHidden = 'menu-left__control_hidden';
const $filterSubmit = $('.menu-left__filter-submit');
const sFilterSubmitFly = '.menu-left__filter-submit-fly';
const $filterSubmitFly = $(sFilterSubmitFly).first();
const cFilterSubmitFlyHidden = 'menu-left__filter-submit-fly_hidden';
const $filterReset = $filterControl.find('.menu-left__filter-reset').first();
let resetHref = $filterReset.attr('href');
const sFinder = '.menu-left__finder';
const sFinderItem = '.menu-left__finder-item';
const lsKey = 'EksmoCatalogMenuClosedSpoilers';
let closedSpoilersName = (isLocalStorageEnabled() && localStorage.getItem(lsKey)) ? JSON.parse(localStorage.getItem(lsKey)) : [];
const sSpoiler = '.menu-left__spoiler';
const cSpoilerOpened = 'menu-left__spoiler_opened';
const $spoilerLink = $('.menu-left__spoiler-link');
const sSpoilerContainer = '.menu-left__spoiler-container';
const sSpoilerCont = '.menu-left__spoiler-cont';
let backUrl = '';

function rememberSpoilerState(value, state) {
    const name = value.trim();

    if (isNoEmptyString(name) && typeof state === 'boolean' && isLocalStorageEnabled()) {
        if (state) {
            closedSpoilersName = closedSpoilersName.filter((item) => item !== name);
        } else if (!closedSpoilersName.includes(name)) {
            closedSpoilersName.push(name);
        }

        localStorage.setItem(lsKey, JSON.stringify(closedSpoilersName));
    }
}

function setBackUrl(e) {
    if (!e.target.checked) {
        backUrl = $(e.target).attr('data-single-back-filter-url');
        setPositionFilterSubmitFly($(this));
    } else {
        backUrl = '';
    }
}

function setSubmitHref() {
    if ($filterSubmit.length && $filterReset.length && isNoEmptyString(resetHref)) {
        const urlGet = getUrlByInputs($menu.find(sFilterInput));
        const inputValues = urlGet.split('&');
        const [singleInputName, singleInputValue] = inputValues.length === 1 ? inputValues[0].split('=') : ['', ''];
        const cInputCheckbox = `${sFilterInput}[name="${singleInputName}"][value="${singleInputValue}"]`;

        if (isNoEmptyString(urlGet)) {
            resetHref = backUrl || resetHref;

            let newHref = resetHref + ((resetHref.includes('?')) ? '&' : '?') + urlGet;

            if (isNoEmptyString(singleInputName)) {
                const url = $(cInputCheckbox).attr('data-single-filter-url');

                if (isNoEmptyString(url)) {
                    newHref = url;
                }

                document.querySelector(cInputCheckbox).addEventListener('change', setBackUrl);
            }

            $filterSubmit.attr('href', newHref);
            $filterControl.removeClass(cFilterControlHidden);
        } else if (backUrl) {
            $filterSubmit.attr('href', backUrl);
            $filterControl.removeClass(cFilterControlHidden);
        } else {
            $filterSubmit.attr('href', resetHref);
            document.querySelector(cInputCheckbox)?.removeEventListener('change', setBackUrl);
        }
    }
}

setSubmitHref();

// Spoiler

if (isLocalStorageEnabled()) {
    $spoilerLink.each(function () {
        const $link = $(this);

        if (closedSpoilersName.includes($link.text().trim())) {
            $link.closest(sSpoiler).removeClass(cSpoilerOpened);
        }
    });
}

$spoilerLink.on('click', function (e) {
    const $link = $(this);
    const $parent = $link.closest(sSpoiler);
    const $container = $parent.find(sSpoilerContainer);
    const $cont = $parent.find(sSpoilerCont);

    if ($container.length && $cont.length) {
        const state = !$parent.hasClass(cSpoilerOpened);

        e.preventDefault();

        if (state) {
            $container.height($cont.outerHeight(true));

            setTimeout(() => {
                $container.removeAttr('style');
                $parent.addClass(cSpoilerOpened);
            }, speed);
        } else {
            $container.height($cont.outerHeight(true));
            $container.height(0);
            $parent.removeClass(cSpoilerOpened);
        }

        rememberSpoilerState($link.text(), state);
    }
});

// END Spoiler

// Filter

function setPositionFilterSubmitFly($inputCont) {
    const offsetTop = Math.abs(($inputCont.outerHeight() - $filterSubmitFly.outerHeight()) / 2);
    const offsetLeft = $inputCont.closest($menu).width();

    setSubmitHref();

    if ($inputCont.length) {
        $filterSubmitFly.css({ 'top': $inputCont.position().top - offsetTop, 'left': $inputCont.position().left + offsetLeft });
        $filterSubmitFly.removeClass(cFilterSubmitFlyHidden);
    }

    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'filter',
        eventCategory: 'microchast',
        eventLabel: $inputCont.find('.checkbox__content-offset').text().replace(/[0-9]/g, '').trim(),
    });
}

$menu.on('eksmoClickResultItemFinder', sFinder, function (e, arrData) {
    const $searchItem = $(this).closest(sFinderItem);
    const $template = $searchItem.find('.menu-left__item-result-finder-template').first();
    const $item = $searchItem.siblings(sFinderItem);
    const $existingTargetInput = $item.find(`${sFilterInput}[value=${arrData.id}]`);

    if ($existingTargetInput.length) {
        $existingTargetInput.prop('checked', true);
    } else if ($template.length) {
        let htmlTemplate = $template.html();

        if (htmlTemplate.length) {
            Object.keys(arrData).forEach((propName) => {
                htmlTemplate = htmlTemplate.replace(new RegExp(`{${propName}}`, 'ig'), arrData[propName]);
            });

            htmlTemplate = htmlTemplate.replace(new RegExp(/\{(.+?)\}/, 'ig'), '');
            $searchItem.after(htmlTemplate);

            const $input = $searchItem.next(sFinderItem).find('.checkbox').first().find('input[type=checkbox]');

            $input.prop('checked', true);
            $input.trigger('change');
            setSubmitHref();
        }
    }

    setPositionFilterSubmitFly($(this).closest('.menu-left__spoiler-cont').find('.menu-left__item-search').next());
    setSubmitHref();
});

$menu.on('change', sFilterInput, function () {
    setPositionFilterSubmitFly($(this).closest('.menu-left__link'));
});

$(document).on('click', (e) => {
    if ($(e.target).is('.finder__result-item') || $(e.target).closest(sFilterSubmitFly).length || $(e.target).is(sFilterSubmitFly) || $(e.target).is(sFilterInput)) {
        return;
    }

    $filterSubmitFly.addClass(cFilterSubmitFlyHidden);
    e.stopPropagation();
});

// END Filter