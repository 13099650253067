import { onScreen } from 'Lib/on-screen/on-screen';

export function setBgPreviewVideo($video) {
    const videoID = $video.attr('data-video-id');

    if (typeof videoID === 'string' && videoID.length) {
        $video.css({
            'background-image': `url(//i.ytimg.com/vi/${videoID}/0.jpg)`,
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
            'background-size': '100%',
            'position': 'relative',
        });

        $video.html('<span class="video__icon-play"></span>');

        if (!$video.hasClass('video_no-player')) {
            $video.on('click', function () {
                const $this = $(this);
                let videoWidth = '100%';
                let videoHeight = '100%';
                const dataWidth = $this.attr('data-video-width');
                const dataHeight = $this.attr('data-video-height');

                if (typeof dataWidth === 'string' && dataWidth.length) {
                    videoWidth = parseInt(dataWidth);
                }

                if (typeof dataHeight === 'string' && dataHeight.length) {
                    videoHeight = parseInt(dataHeight);
                }

                $this.addClass('video_active');
                $this.css({ 'background-image': 'none' });
                $this.html(`<iframe id="${videoID}" title="" width="${videoWidth}" height="${videoHeight}" src="https://www.youtube.com/embed/${videoID}?rel=0&showinfo=0&autoplay=1&enablejsapi=1&origin=${window.location.origin}&widgetid=1" frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media"></iframe>`);
            });
        }
    }
}

onScreen($('.video'), setBgPreviewVideo);