// Получение цены с правильным форматирование
export function formatPrice(price) {
    let curPrice = price;

    if (typeof curPrice === 'number') {
        curPrice = curPrice.toString();
    }

    if (typeof curPrice === 'string' && curPrice !== 'undefined' && curPrice !== '0') {
        return (parseInt(curPrice.replace(/\s| |&nbsp|-|р.|%/g, '')) || 0).toString();
    }

    return '';
}