import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const host = 'https://eksmo.ru';
const hostDev = 'https://dev.eksmo.ru';

function getGets(url = '') {
    const urlGet = url.split('?')[1] || '';

    return urlGet.split('&');
}

export const linkParsing = {
    nameBookVideoReview: 'video-review',
    nameBookFormatE: 'format-ebook',
    nameBookFormatA: 'format-audio',
    nameBookFormatPOD: 'format-pod',
    nameCatalogFormatP: 'p',
    nameCatalogFormatE: 'e',
    nameCatalogFormatA: 'a',
    nameCatalogFormatPOD: 'pod',

    getOurHostUrl(value) {
        let url = value;

        if (isNoEmptyString(url)) {
            if (url.indexOf('http') === 0) {
                if (url.indexOf(host) === 0) {
                    url = url.replace(host, '');
                } else if (url.indexOf(hostDev) === 0) {
                    url = url.replace(hostDev, '');
                } else {
                    return false;
                }
            }

            if (url.length) {
                url = url.replace('?.*$', '');

                if (url.length) {
                    return url;
                }
            }
        }

        return false;
    },

    isAuthor(value) {
        const url = this.getOurHostUrl(value);

        if (url) {
            let result = url.match(/^\/authors\/.*(ID|ITD)\d+\/?/g);

            if (Array.isArray(result)) {
                result = result[0];

                if (isNoEmptyString(result)) {
                    return result;
                }
            }
        }

        return false;
    },

    getAuthorID(value) {
        let url = this.getOurHostUrl(value);

        if (url) {
            url = this.isAuthor(url);

            if (url) {
                let result = url.match(/(ID|ITD)\d+/g);

                if (Array.isArray(result)) {
                    result = result[0];

                    if (isNoEmptyString(result)) {
                        return result;
                    }
                }
            }
        }

        return false;
    },

    isBook(value) {
        const url = this.getOurHostUrl(value);

        if (url) {
            let result = url.match(/^(\/book\/|\/catalog\/book\/).*(ID|ITD|IDM|430|410)\d+\/?/g);

            if (Array.isArray(result)) {
                result = result[0];

                if (isNoEmptyString(result)) {
                    return result;
                }
            }
        }

        return false;
    },

    isEBook(value) {
        const url = this.getOurHostUrl(value);

        if (url) {
            let result = url.match(/^(\/ebook\/|\/catalog\/ebook\/).*(ID|ITD|IDM|430|410)\d+\/?/g);

            if (Array.isArray(result)) {
                result = result[0];

                if (isNoEmptyString(result)) {
                    return result;
                }
            }
        }

        return false;
    },

    isAudioBook(value) {
        const url = this.getOurHostUrl(value);

        if (url) {
            let result = url.match(/^(\/audio\/|\/catalog\/audio\/).*(ID|ITD|IDM|430|410)\d+\/?/g);

            if (Array.isArray(result)) {
                result = result[0];

                if (isNoEmptyString(result)) {
                    return result;
                }
            }
        }

        return false;
    },

    getTypeBook(value) {
        if (this.isBook(value)) {
            return 'book';
        }

        if (this.isEBook(value)) {
            return 'ebook';
        }

        if (this.isAudioBook(value)) {
            return 'audio';
        }

        return false;
    },

    getBookNomcode(value) {
        let url = this.getOurHostUrl(value);

        if (url) {
            url = this.isBook(url);

            if (url) {
                let result = url.match(/(ID|ITD|IDM|430|410)\d+/g);

                if (Array.isArray(result)) {
                    result = result[0];

                    if (isNoEmptyString(result)) {
                        let prefix = result.match(/(ID|ITD|IDM|430|410)/g);

                        if (Array.isArray(prefix)) {
                            prefix = prefix[0];

                            if (typeof prefix === 'string' && prefix.length) {
                                let onlyNumbers = result.replace(/(ID|ITD|IDM|430|410)/, '');

                                if (result.length) {
                                    const qtyZeros = 15 - onlyNumbers.length;

                                    for (let i = 0; i < qtyZeros; i++) {
                                        onlyNumbers = `0${onlyNumbers}`;
                                    }

                                    return prefix + onlyNumbers;
                                }
                            }
                        }
                    }
                }
            }
        }

        return false;
    },

    getBookFormat(url = '') {
        const arUrl = url.split('#');

        if (arUrl.length > 1) {
            return arUrl[arUrl.length - 1];
        }

        return false;
    },

    isBookFormatP(url) {
        return (
            this.getBookFormat(url) !== this.nameBookFormatE
            && this.getBookFormat(url) !== this.nameBookFormatA
            && this.getBookFormat(url) !== this.nameBookFormatPOD
        );
    },

    isBookVideoReview(url) {
        return (this.getBookFormat(url) === this.nameBookVideoReview);
    },

    isBookFormatE(url) {
        return (this.getBookFormat(url) === this.nameBookFormatE);
    },

    isBookFormatA(url) {
        return (this.getBookFormat(url) === this.nameBookFormatA);
    },

    isBookFormatPOD(url) {
        return (this.getBookFormat(url) === this.nameBookFormatPOD);
    },

    getCatalogFormat(url) {
        const arGets = getGets(url);

        return arGets.format;
    },

    isCatalogFormatP(url) {
        return (this.getCatalogFormat(url) === this.nameCatalogFormatP);
    },

    isCatalogFormatE(url) {
        return (this.getCatalogFormat(url) === this.nameCatalogFormatE);
    },

    isCatalogFormatA(url) {
        return (this.getCatalogFormat(url) === this.nameCatalogFormatA);
    },

    isCatalogFormatPOD(url) {
        return (this.getCatalogFormat(url) === this.nameCatalogFormatPOD);
    },

    isSeries(value) {
        const url = this.getOurHostUrl(value);

        if (url) {
            let result = url.match(/^\/series\/.*(ID|ITD)\d+\/?/g);

            if (Array.isArray(result)) {
                result = result[0];

                if (isNoEmptyString(result)) {
                    return result;
                }
            }
        }

        return false;
    },

    getSeriesCode(value) {
        let url = this.getOurHostUrl(value);

        if (url) {
            url = this.isSeries(url);

            if (url) {
                let result = url.match(/(ID|ITD)\d+/g);

                if (Array.isArray(result)) {
                    result = result[0];

                    if (isNoEmptyString(result)) {
                        return result;
                    }
                }
            }
        }

        return false;
    },
};