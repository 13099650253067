import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';

const elsName = document.querySelectorAll('.cycles-books__item');

function setHeightItem(item, event = true) {
    const elItemImg = item.querySelector('.cycles-books__item-img');
    const cOpened = 'cycles-books__item_opened';
    const widthItemImg = elItemImg.scrollWidth;
    const heightItemImg = elItemImg.scrollHeight;
    const ratio = widthItemImg / heightItemImg;
    const newWidthItemImg = 120;
    const newHeightItemImg = newWidthItemImg / ratio;

    if (!item.classList.contains(cOpened) || !event) {
        const heightItemFooter = item.querySelector('.cycles-books__item-footer-box')?.scrollHeight + item.querySelector('.cycles-books__item-title-box')?.scrollHeight;
        const thatMore = heightItemImg > heightItemFooter ? 'img' : 'footer';

        console.log(heightItemFooter - newHeightItemImg, 'heightItemImg');

        item.classList.add(cOpened);

        if (getW() > eksmoBP.mdMin) {
            if (thatMore === 'img') {
                item.style.height = heightItemImg;
            } else {
                item.style.paddingBottom = `${heightItemFooter - newHeightItemImg + 24}px`;
            }
        }
    } else {
        item.classList.remove(cOpened);
        item.removeAttribute('style');
    }

    if (!event) {
        document.querySelector('.cycles-books').classList.add('cycles-books_init');
    }
}

$(window).on('load', () => {
    elsName?.forEach((item) => {
        setHeightItem(item, false);

        item.addEventListener('click', (e) => {
            const target = e.target;

            if ((target.classList.contains('cycles-books__item-see-all')) || !target.closest('.cycles-books__item-footer-box')) {
                setHeightItem(item);
            }
        });
    });
});