import { linkParsing } from 'Lib/link-parsing/link-parsing';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $tabs = $('.tabs');
const $body = $('body');

function getIndex($link, defaultIndex) {
    const dataIndex = $link.attr('data-tab-index');

    if (isNoEmptyString(dataIndex)) {
        const intDataAttr = parseInt(dataIndex);

        return !Number.isNaN(intDataAttr) ? intDataAttr : defaultIndex;
    }

    return defaultIndex;
}

function init() {
    const sTabs = '.tabs';
    const $thisTabs = $(sTabs);
    const sLink = '.tabs__link';
    const sCont = '.tabs__container';
    const cLinkActive = 'tabs__link_active';
    const cContActive = 'tabs__container_active';

    function switcher($elLink, i = 0, attributeTab = false) {
        let $parent = $elLink.closest('.tabs');
        const $parentClassTab = $parent.closest(`.${$parent.attr('data-parent-class-tab')}`);

        if (attributeTab) {
            $parent = $(`${sTabs}[data-tab=${attributeTab}]`);
        }

        const $link = $parent.find(sLink);
        const $cont = $parent.find(sCont);
        const index = getIndex($elLink, i);

        if (typeof index === 'number') {
            const $curentCont = $cont.eq(index);
            const $curentLink = $link.eq(index);
            const dataTabItemVisible = $elLink.attr('data-tab-item-visible');

            if ($curentCont.length && $curentLink.length) {
                $link.removeClass(cLinkActive);
                $curentLink.addClass(cLinkActive);
                $cont.removeClass(cContActive);
                $curentCont.addClass(cContActive);

                if ($parentClassTab) {
                    $parentClassTab.find(`[data-tab-visible-item]`).removeClass('js-tab-item-visible');
                    $parentClassTab.find(`[data-tab-visible-item=${dataTabItemVisible}]`).addClass('js-tab-item-visible');
                }

                $body.trigger('toggle.tabs.eksmo', [$parent, index]);
            }
        }
    }

    if (linkParsing.isBookVideoReview(window.location.href)) {
        const attributeBookReview = $('[data-tab="book_reviews"]');

        if (attributeBookReview.length) {
            switcher(attributeBookReview, 1);
        }
    }

    $thisTabs.each(function () {
        const $parent = $(this);
        const $link = $parent.find(sLink);
        const $cont = $parent.find(sCont);

        if (!$link.filter(`.${cLinkActive}`).length) {
            $link.first().addClass(cLinkActive);
        }

        if (!$cont.filter(`.${cContActive}`).length) {
            $cont.first().addClass(cContActive);
        }

        $link.on('click', function () {
            switcher($(this), $link.index(this), false);
        });
    });

    function switchTab($th) {
        const attributeTab = $th?.attr('data-tab');

        if (attributeTab) {
            switcher($th, 0, attributeTab);
        }
    }

    $('[data-tab-index]').on('click', () => switchTab($(this)));

    $body.on('switchTab.tabs.eksmo', (e, $th) => {
        switchTab($th);
    });
}

if ($tabs.length) {
    init();
}

$body.on('init.tabs.eksmo', init);
$body.on('bitrixDynamicComposite.eksmo', init);