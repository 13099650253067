import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'Vendor/owlcarousel/owl.carousel.min';

const sParent = '.gallery';
const sSlider = '.gallery__slider';
const sText = '.gallery__text';
const sTextItems = '.gallery__text-item';
const cTextActive = 'gallery__text-item_active';
const cArrowNoBorder = 'gallery_arrow-no-border';

function setHeightTextBlock($block, numSlide) {
    const num = parseInt(numSlide) || 0;

    $block.each(function () {
        const $textCont = $(this).parent().find(sText);
        const $textItems = $textCont.find(sTextItems);
        const $currentTextBlock = $textItems.eq(num);

        $textCont.height($currentTextBlock.height());
        $textItems.removeClass(cTextActive);
        $currentTextBlock.addClass(cTextActive);
    });
}

function init() {
    $(sSlider).each(function (e) {
        const $thisSlider = $(this);
        const $parent = $thisSlider.closest(sParent);
        let cDots = 'gallery__dots';
        const cSliderOn = 'owl-carousel';
        let cArrowAdditional = ' arrow_no-bg arrow_border';

        cDots += parseInt(e);
        $thisSlider.siblings('.gallery__dots').addClass(cDots);

        if ($parent.hasClass(cArrowNoBorder)) {
            cArrowAdditional = ' arrow_shadow';
        }

        $thisSlider.trigger('destroy.owl.carousel')
            .removeClass(cSliderOn)
            .addClass(cSliderOn)
            .owlCarousel({
                margin: 40,
                navText: [
                    `<div class="gallery__prev arrow arrow_prev${cArrowAdditional}"></div>`,
                    `<div class="gallery__next arrow arrow_next${cArrowAdditional}"></div>`,
                ],
                animateIn: 'fadeIn',
                animateOut: 'fadeOut',
                dotsContainer: `.${cDots}.dots`,
                dotClass: 'dots__item',
                responsive: {
                    0: { items: 1, slideBy: 1, stagePadding: 30, margin: 13, animateIn: false, animateOut: false, dots: false },
                    770: { items: 1, slideBy: 1, nav: true },
                },
                onInitialized(evt) {
                    onScreen($(evt.target).find('.gallery__picture_loader'), loadSrcOrBg);
                    setHeightTextBlock($thisSlider);
                },
            })
            .on('translate.owl.carousel', (evt) => {
                setHeightTextBlock($(evt.currentTarget), evt.item.index);
            });
    });
}

init();

if ($('.timeline').length) {
    $('body').on('eksmoFancyboxAfterLoad', init);
}