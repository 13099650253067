import { isTouch } from 'Lib/istouch/istouch';
import { isRetina } from 'Lib/isretina/isretina';
import { speed } from 'Lib/speed/speed';

const $body = $('body');
const sBook = '.book';
const cBackShow = 'book_back-show';
const cBackLoad = 'book_back-load';
const sImgCont = '.book__image-align';
const sImgBack = '.book__img-back';
const sImg = '.book__img';

function changeCover(e) {
    const $img = $(e.target);
    const $book = $img.closest(sBook);
    const $imgBack = $book.find(sImgBack);

    function showBack() {
        $book.addClass(cBackShow);
    }

    function showFront() {
        $book.removeClass(cBackShow);
    }

    if (e.type === 'mouseleave' || e.type === 'mouseout') {
        showFront();
    } else if (e.pageX - $img.offset().left > $img.width() / 2) {
        showBack();
    } else {
        showFront();
    }

    // Сброс остальных книжек
    $(sBook).not($book).removeClass(cBackShow);

    // Загрузка изображения задней обложки при ховере в любое место картинки
    if (!$imgBack.length) {
        const nomcode = $book.attr('data-id');

        if (typeof nomcode === 'string' && nomcode.length) {
            const $imgCont = $img.closest(sImgCont);

            $imgCont.append(`<img class="book__img book__img_shadow book__img-back" src="https://cdn.eksmo.ru/v2/${nomcode}/COVER/cover4__w${isRetina() ? '410' : '220'}.jpg" alt="">`);

            $imgCont.find(sImgBack).one('load', () => {
                $book.addClass(cBackLoad);
            });
        }
    }
}

function setListeners(e) {
    const sHoverImg = `.book_back ${sImg}:not(${sImgBack})`;
    const $hoverImg = typeof e !== 'undefined' ? $(e.target).find(sHoverImg) : $(sHoverImg);

    $hoverImg.on('mousemove mouseleave', $.throttle(speed, changeCover));
}

if (!isTouch()) {
    setListeners();
    $body.on('eksmoPagenavAjax', setListeners);
    $('.books-slider_post-load').on('eksmoBooksSliderPostLoaded', setListeners);

    $body.on('eksmoFancyboxAfterLoad', (e, instance, current) => {
        setListeners(current.$slide[0]);
    });
}