import { speed } from 'Lib/speed/speed';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'Vendor/owlcarousel/owl.carousel.min';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';

const sParent = '.authors-slider';
const sSlider = '.authors-slider__slider';
const cSliderM = 'authors-slider_m';
const cSliderOn = 'owl-carousel';
const sImgLoader = '.authors-slider__img-loader';

function setSlider(e) {
    let $slider = $(sSlider);

    if (typeof e !== 'undefined' && typeof e.target !== 'undefined') {
        $slider = $(e.target).find(sSlider);
    }

    $slider.each(function () {
        const $thisSlider = $(this);
        const $parent = $thisSlider.closest(sParent);

        if (!$parent.hasClass(cSliderM) || ($parent.hasClass(cSliderM) && getW() < eksmoBP.mdMin)) {
            $thisSlider.each(() => {
                $thisSlider.trigger('destroy.owl.carousel')
                    .removeClass(cSliderOn)
                    .addClass(cSliderOn)
                    .owlCarousel({
                        smartSpeed: speed,
                        dots: false,
                        navClass: ['authors-slider__prev arrow arrow_prev arrow_no-bg', 'authors-slider__next arrow arrow_next arrow_no-bg'],
                        navText: ['', ''],
                        responsive: {
                            0: { items: 1 },
                            400: { items: 2 },
                            560: { items: 3 },
                            700: { items: 4 },
                            992: { items: 4, slideBy: 4, nav: true },
                            1150: { items: 5, slideBy: 5, nav: true },
                            1300: { items: 6, slideBy: 6, nav: true },
                        },
                        onInitialized(evt) {
                            onScreen($(evt.target).find(sImgLoader), loadSrcOrBg);
                        },
                    });
            });
        } else {
            const $item = $thisSlider.find('.author');

            onScreen($thisSlider.find(sImgLoader), loadSrcOrBg);
            $parent.removeClass('authors-slider authors-slider_crop authors-slider_m');
            $item.removeClass('authors-slider__item');
            $item.unwrap();
            $item.after('<div class="authors__item-helper author"></div>');
        }
    });
}

setSlider();
$('body').on('eksmoPagenavAjax', '.pagenav-ajax', setSlider);