const $body = $('body');
const sInput = '.radio__input';

// Связываем радиокнопки по data-radio-num
$body.on('change', sInput, function () {
    const $radioNum = $(this).data('radio-num');

    $(`${sInput}[data-radio-num=${$radioNum}]`).prop('checked', true);
});

$body.on('click', '.radio', function () {
    const $this = $(this);
    const $input = $this.find(sInput);

    if ($this[0].tagName !== 'LABEL') {
        $input.prop('checked', !$input.is(':checked'));
    }

    $this.trigger('eksmoChangeRadio', [$input.is(':checked')]);
});