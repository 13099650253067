import { linkParsing } from 'Lib/link-parsing/link-parsing';
import { eventSender } from 'Lib/event-sender/event-sender';

$('.article-t').on('click', 'a', function () {
    const $this = $(this);
    const href = $this.attr('href');

    if (typeof href === 'string' && href.length) {
        const sH = '.h, h1, h2, h3, h4, h5, h6';
        let eventCategory = 'Клик по тексту';

        if ($this.closest(sH).length || $this.is(sH)) {
            eventCategory = 'Клик в заголовке';
        } else if ($this.is('.btn')) {
            eventCategory = 'Клик по кнопке';
        }

        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'journal_click',
            eventCategory,
            eventLabel: href,
        });
    }
});