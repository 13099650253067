import { deleteSvgSprite } from 'Lib/svg-sprite-loader/svg-sprite-loader';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { oLike } from 'Lib/like/like';
import { eksmoBP } from 'Lib/bp/bp';
import { getW } from 'Lib/w/w';
import { isTouch } from 'Lib/istouch/istouch';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { CheckboxAccept } from 'Lib/checkbox-accept/checkbox-accept';
import { eventSender } from 'Lib/event-sender/event-sender';

export class FastViewBook {
    constructor(nomcode = null) {
        this._nomcode = nomcode;
        this._$body = $('body');
        this._build = `${window.URLBuild}/site/book-fast-view`;
        this._scriptSrc = `${this._build}/js/script.js`;
        this._styleSrc = `${this._build}/css/style.css`;
    }

    _loadScript() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');

            script.onload = () => resolve();
            script.onerror = () => reject();
            script.src = this._scriptSrc;
            document.head.insertBefore(script, document.head.firstChild);
        });
    }

    _loadStyle() {
        return new Promise((resolve, reject) => {
            const style = document.createElement('link');

            style.onload = () => resolve();
            style.onerror = () => reject();
            style.href = this._styleSrc;
            style.type = 'text/css';
            style.rel = 'stylesheet';
            document.head.insertBefore(style, document.head.firstChild);
        });
    }

    _loadResources(cb) {
        Promise.all([this._loadScript(), this._loadStyle()]).then(cb);
    }

    _deleteResources() {
        $(`script[src="${this._scriptSrc}"]`).remove();
        $(`link[href="${this._styleSrc}"]`).remove();
        deleteSvgSprite('book-fast-view');
    }

    show(cb, sTab, withMobile) {
        if (withMobile || (getW() > eksmoBP.smMax && !isTouch() && isNoEmptyString(this._nomcode))) {
            const sBookPage = '.book-page_fast-view';

            $.fancybox.close();

            $.fancybox.open({
                baseClass: 'fancybox-book-fast-view',
                type: 'ajax',
                src: isLocalhost()
                    ? `/eksmo/_verstka/ajax/book-fast-view.php?NC=${this._nomcode}`
                    : `/ajax/book/fast-view/?NC=${this._nomcode}`,
                touch: false,
                afterLoad: (instance, current) => {
                    this._$body.trigger('eksmoFancyboxAfterLoad', [instance, current]);

                    this._loadResources(() => {
                        if (typeof sTab === 'string') {
                            this._$body.trigger('switchTab.tabs.eksmo', [$(sTab)]);
                        }

                        this._$body.trigger('init.tabs.eksmo');
                        oLike.init(`${sBookPage} .js-like`);
                        $('.fancybox__fast-view-loader').remove();
                        current.$slide.find(sBookPage).removeClass('fancybox__fast-view-loading');
                        this._$body.trigger('init.fastViewBook');

                        document.querySelectorAll('.mail-form__form').forEach((item) => {
                            const checkboxes = new CheckboxAccept(
                                'subscription',
                                '.mail-form__form',
                                '.checkbox input',
                                '.checkbox__content-offset',
                                '.mail-form__btn',
                                item,
                            );

                            checkboxes.setEventCheckboxAccept();
                        });

                        eventSender.setViewBookMD();
                        setupButtonAddToBasket(window.settingsWl);
                    });

                    if (typeof cb === 'function') {
                        cb();
                    }

                    this._$body.on('eksmoResizeWidth.fastViewBook', (e, newWidth) => {
                        if (newWidth < eksmoBP.mdMin) {
                            instance.close();
                        }
                    });
                },
                afterShow: (instance, current) => {
                    current.$slide.find('.audio-play').each(function () {
                        $(this).trigger('eksmoCallAudioPlaySet');
                    });
                },
                beforeClose: (instance, current) => {
                    this._$body.off('eksmoResizeWidth.fastViewBook');

                    current.$slide.find('.audio-play').each(function () {
                        $(this).trigger('eksmoCallAudioPlayPause');
                    });

                    $(sBookPage).addClass('fancybox__fast-view-loading');
                    this._deleteResources();
                },
            });
        }
    }
}
