import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { isTouch } from 'Lib/istouch/istouch';
import { FastViewBook } from 'Lib/fast-view-book/fast-view-book';
import { speed } from 'Lib/speed/speed';

const $body = $('body');
const sBookFastView = '.book_fast-view';
const sImgCont = '.book__image-align';

function setArrow($book) {
    const $books = $book.closest('.books');
    const index = $book.attr('data-pos');

    function setArrowHtml($curBook, arrowClass) {
        if ($curBook.length) {
            const $bookPage = $('.book-page_fast-view');

            $bookPage.append(`<div class="book-page__fast-view-arrow arrow ${arrowClass}"></div>`);

            $bookPage.find(`.book-page__fast-view-arrow.${arrowClass}`).on('click', () => {
                const fastViewBook = new FastViewBook($curBook.attr('data-id'));

                fastViewBook.show(() => setArrow($curBook));
            });
        }
    }

    if (typeof index === 'string' && index.length) {
        setArrowHtml($books.find(`${sBookFastView}[data-pos=${(parseInt(index) - 1)}]`).first(), 'arrow_prev');
        setArrowHtml($books.find(`${sBookFastView}[data-pos=${(parseInt(index) + 1)}]`).first(), 'arrow_next');
    }
}

function setListeners(e) {
    const $book = typeof e !== 'undefined' ? $(e.target).find(sBookFastView) : $(sBookFastView);
    const sBtn = '.book__fast-view-btn';

    $book.on('mousemove', $.throttle(speed, function () {
        const $curBook = $(this);

        if (!$curBook.find(sBtn).length) {
            const $imgCont = $curBook.find(sImgCont);

            $imgCont.append('<div class="book__fast-view-btn btn btn_small btn_nowrap btn_shadow">Быстрый просмотр</div>');

            $imgCont.find(sBtn).on('click', function (evt) {
                const fastViewBook = new FastViewBook($(this).closest(sBookFastView).attr('data-id'));
                const formatBook = $(this).closest(sBookFastView).attr('data-format');
                let indexTab;

                if (formatBook === 'paper') {
                    indexTab = 0;
                } else if (formatBook === 'el') {
                    indexTab = 1;
                } else if (formatBook === 'audio') {
                    indexTab = 2;
                }

                evt.preventDefault();
                fastViewBook.show(() => setArrow($curBook), `.prices-card__tab[data-tab-index="${indexTab}"]`);
            });
        }
    }));
}

if (getW() > eksmoBP.smMax && !isTouch()) {
    setListeners();
    $body.on('eksmoPagenavAjax', setListeners);
    $('.books-slider_post-load').on('eksmoBooksSliderPostLoaded', setListeners);

    $body.on('eksmoFancyboxAfterLoad', (e, instance, current) => {
        setListeners(current.$slide[0]);
    });
}