import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const oCheckbox = {
    check(selector) {
        if (selector.length && $(selector).length) {
            $('body').on('change', `${selector} input[type=checkbox]`, function () {
                const $this = $(this);
                const $label = $this.parents(selector);
                const href = $label.attr('data-href');

                if (isNoEmptyString(href)) {
                    document.location = href;
                }

                $label.trigger('eksmoChangeCheckbox', [$this.is(':checked')]);
            });
        }
    },

    init(s, func) {
        oCheckbox.check(s, func);
    },
};

oCheckbox.init('.checkbox');