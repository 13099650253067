import { eksmoBP } from 'Lib/bp/bp';
import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { speed } from 'Lib/speed/speed';
import { FastViewBook } from 'Lib/fast-view-book/fast-view-book';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { isTouch } from 'Lib/istouch/istouch';
import { formatPrice } from 'Lib/format-price/format-price';

const sBook = '.text-insert-card_book-v2';
const $book = $(sBook);
const $bookLink = $(`${sBook} a.text-insert-card__pic, ${sBook} a.text-insert-card__name, ${sBook} a.text-insert-card__btn`);
const $likeBtn = $('.text-insert-card__like .btn');
const cLikeBtnSmall = 'btn_small';
const $body = $('body');

function changeClassOnLike() {
    const curWeight = getW();

    $likeBtn.toggleClass(cLikeBtnSmall, !(curWeight > eksmoBP.xxsMax && curWeight < eksmoBP.smMin));
}

$book.each(function () {
    const $this = $(this);

    $this.find('.text-insert-card__btn_wl-off').attr('href', $this.find('a.text-insert-card__pic').attr('href'));
});

function sendGA4(elCurrAddedBook, typeEvent) {
    if (elCurrAddedBook) {
        const $Book = elCurrAddedBook.closest(sBook);

        if ($Book.length) {
            const price = Number(formatPrice($Book.find('.text-insert-card__price-value').text()));
            const oldPrice = Number(formatPrice($Book.find('.text-insert-card__price-old').text()));
            const isPreorder = Number($Book.find('.btn-shop').first().text().trim().includes('Предзаказ'));
            const $BtnBasket = $Book.find('.text-insert-card__btn');
            const btnBasketAttr = $BtnBasket.attr('data-book24-widget-basket-button-title');
            const textBtnBasket = btnBasketAttr || $BtnBasket.attr('data-book24-widget-order-button-title') || $BtnBasket.text().trim();
            const objBookInfo = {
                price,
                isPreorder,
                textBtnBasket,
                name: $Book.find('.text-insert-card__name')?.text().trim(),
                id: $Book.attr('data-id'),
                oldPrice,
                discountPrice: oldPrice && price ? oldPrice - price : null,
                brand: $Book.attr('data-brand'),
                firstCategory: $Book?.attr('data-first-category'),
                lastCategory: $Book?.attr('data-last-category'),
                author: $Book?.find('.text-insert-card__author')?.text().trim(),
                type: $Book.find('.text-insert-card__format').text().trim(),
                list: ($Book.closest('.author-news').length || $Book.closest('.event-detail').length) ? 'новость - врезка' : 'журнал - врезка',
            };

            if (objBookInfo.type === 'Электронная книга') {
                objBookInfo.type = 'Электронная';
            } else if (objBookInfo.type === 'Аудиокнига') {
                objBookInfo.type = 'Аудиокнига';
            } else {
                objBookInfo.type = 'Бумажная';
            }

            eventSender.impressionsClickAddBasketBookGA4(objBookInfo, typeEvent);
        }
    }
}

changeClassOnLike();
$body.on('eksmoResizeWidth', changeClassOnLike);

onScreen($bookLink, ($el) => {
    sendGA4($el.closest(sBook), 'impressions');
}, 0, 0, 0.8);

$bookLink.on('click', function () {
    const $this = $(this);
    const $thisBook = $this.closest(sBook);

    if ($thisBook.length) {
        sendGA4($this, 'click');
    }
});

// События по клику на кнопку "В корзину"/"На Литрес"
$body.on('addBookBasket.eksmo', (e, data) => {
    const currAddedBook = $(`.text-insert-card__btn[data-book24-widget=${data.productId}]`).first();

    if (currAddedBook.length) {
        sendGA4(currAddedBook, 'addBasket');
    }
});

$('.text-insert-card__btn:not(.btn-shop_b24)').on('click', function () {
    sendGA4($(this), 'addBasket');
});

$('.text-insert-card__btn').on('click', function () {
    const $this = $(this);

    if ($this.closest(sBook).find('.text-insert-card__format').text() === 'Электронная книга') {
        eventSender.customDL({ 'event': 'buyLitres', 'eventCategory': 'ecommerce', 'eventAction': 'click', 'eventLabel': 'eBook', 'eventStatus': 'success', 'eventNonInteraction': 'false', 'firingOptions': 'oncePerEvent' });
    } else {
        eventSender.customDL({ 'event': 'buyLitres', 'eventCategory': 'ecommerce', 'eventAction': 'click', 'eventLabel': 'aBook', 'eventStatus': 'success', 'eventNonInteraction': 'false', 'firingOptions': 'oncePerEvent' });
    }
});

// Быстрый просмотр
if (getW() > eksmoBP.smMax && !isTouch()) {
    const sBtn = '.text-insert-card__fast-view-btn';

    $('.text-insert-card_fast-view').on('mousemove', $.throttle(speed, function () {
        const $curBook = $(this);
        const formatBook = $curBook.attr('data-format');
        let indexTab;

        if (formatBook === 'paper') {
            indexTab = 0;
        } else if (formatBook === 'el') {
            indexTab = 1;
        } else if (formatBook === 'audio') {
            indexTab = 2;
        }

        if (!$curBook.find(sBtn).length) {
            $curBook.find('.text-insert-card__btns').after('<div class="text-insert-card__fast-view-btn btn btn_border" data-tab-index="1" data-tab="book_format">Быстрый просмотр</div>');

            $curBook.find(sBtn).on('click', function (e) {
                const fastViewBook = new FastViewBook($(this).closest(sBook).attr('data-id'));

                e.preventDefault();
                fastViewBook.show('', `.prices-card__tab[data-tab-index="${indexTab}"]`);
            });
        }
    }));
}