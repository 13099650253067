import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { getW } from 'Lib/w/w';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { eventSender } from 'Lib/event-sender/event-sender';
import 'Vendor/owlcarousel/owl.carousel.min';

const $parent = $('.meetings');
const $meetingsLink = $('.meetings__link');
const $slider = $('.meetings__slider');
const cSliderOn = 'owl-carousel';
const sImgForLoad = '.meetings__pic_loader';
let lastStateIsMobileScreen = isMobileScreen();

function isMobileScreen() {
    return (getW() < eksmoBP.smMin);
}

function initLoaderImages($els) {
    onScreen($els, loadSrcOrBg);
}

function setSliders() {
    if (isMobileScreen()) {
        $slider.trigger('destroy.owl.carousel').removeClass(cSliderOn);
        initLoaderImages($slider.find(sImgForLoad));
    } else {
        $slider.each(function (evt) {
            const e = parseInt(evt);
            const $this = $(this);
            const qtySlides = $this.find('.meetings__link').length;
            const sliderParamItems = qtySlides > 1 && !$this.closest('.meetings').hasClass('meetings_one-slide') ? 2 : 1;

            $this.find('.meetings__link-arrow').remove();

            if (qtySlides > 0) {
                let cArrows = 'meetings__arrows';
                let cDots = 'meetings__dots';

                cArrows += e;
                cDots += e;
                $this.closest($parent).find('.meetings__arrows').addClass(cArrows);
                $this.closest($parent).find('.meetings__dots').addClass(cDots);

                $this.trigger('destroy.owl.carousel')
                    .removeClass(cSliderOn)
                    .addClass(cSliderOn)
                    .owlCarousel({
                        items: 1,
                        slideBy: 1,
                        autoWidth: false,
                        smartSpeed: speed * (isMobileScreen() ? 1 : 3),
                        dotsContainer: `.${cDots}.dots`,
                        dotClass: 'dots__item',
                        nav: true,
                        navContainer: `.${cArrows}`,
                        navClass: ['meetings__prev arrow arrow_prev arrow_no-bg', 'meetings__next arrow arrow_next arrow_no-bg'],
                        navText: ['', ''],
                        responsive: {
                            1100: { items: sliderParamItems, slideBy: sliderParamItems },
                        },
                        onInitialized(event) {
                            initLoaderImages($(event.target).find(sImgForLoad));
                        },
                    });
            }
        });
    }
}

function sendEventGA4($el, event) {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: event,
        eventCategory: $('title').text().trim(),
        eventLabel: `${$el.find('.meetings__item').length > 1 ? 'Встречи и события' : 'Книжные новости'} - ${$el.find('.meetings__name').text()}`,
    });
}

setSliders();

$('body').on('eksmoResizeWidth', () => {
    if (isMobileScreen() !== lastStateIsMobileScreen) {
        setSliders();
        lastStateIsMobileScreen = isMobileScreen();
    }
});

onScreen($meetingsLink, ($el) => {
    sendEventGA4($el, 'view');
}, 0, 0, 0.8);

$meetingsLink.on('click', function () {
    sendEventGA4($(this), 'click');
});