import { onScreen } from 'Lib/on-screen/on-screen';

const src = 'https://www.instagram.com/embed.js';

onScreen($('.instagram-list'), () => {
    if ($(`script[src="${src}"]`).length === 0) {
        const script = document.createElement('script');

        script.src = src;
        document.head.appendChild(script);
    }
});