import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

let sParent = '';
const cLikeButton = 'js-like-button';
const cDislikeButton = 'js-dislike-button';
const sNum = '.like-dislike__num';
const cActive = 'js-active';
const cSingle = 'like-dislike_single';

export const oLikeDislike = {
    init(selector) {
        sParent = selector;
        this.load();
        $(sParent).on('click', `.${cLikeButton}`, this.add);
        $(sParent).on('click', `.${cDislikeButton}`, this.add);
    },

    load() {
        const guidLike = {};
        let qty = 0;

        $(sParent).each(function () {
            const type = $(this).attr('data-type');
            const id = $(this).attr('data-id');

            if (type?.length && id?.length) {
                if (!guidLike[type]) {
                    guidLike[type] = [];
                }

                if (!guidLike[type].includes(id)) {
                    guidLike[type].push(id);
                    qty++;
                }
            }
        });

        if (qty) {
            $.post('/ajax/like_dislike/list/', { aGuid: guidLike }, (data) => {
                const mdbData = $.parseJSON(data);

                $(sParent).each(function () {
                    const $this = $(this);
                    const type = $this.attr('data-type');
                    const id = $this.attr('data-id');
                    let likeDislike = [0, 0];

                    if (isNoEmptyString(type) && isNoEmptyString(id) && mdbData?.[type]?.[id]) {
                        likeDislike = mdbData[type][id];
                    }

                    $(`.${cLikeButton} ${sNum}`, this).html(likeDislike[0]);
                    $(`.${cDislikeButton} ${sNum}`, this).html(likeDislike[1]);

                    if (typeof likeDislike[2] !== 'undefined') {
                        if (likeDislike[2] === 0) {
                            $(`.${cDislikeButton}`, this).addClass(cActive);
                        }

                        if (likeDislike[2] === 1) {
                            $(`.${cLikeButton}`, this).addClass(cActive);
                        }
                    }
                });
            });
        }
    },

    add() {
        const $btn = $(this);
        const $parent = $btn.closest(sParent);
        const id = $parent.attr('data-id');
        const type = $parent.attr('data-type');
        const isSingle = $parent.hasClass(cSingle);

        if (type?.length && id?.length) {
            let statusLike = true;

            if ($btn.hasClass(cDislikeButton)) {
                statusLike = false;
            }

            $.getJSON(`/ajax/like_dislike/add/?guid=${id}&type=${type}&value=${statusLike}`, (arrNums) => {
                if (statusLike) {
                    $(`.${cDislikeButton}`, $parent).removeClass(cActive);
                    $(`.${cLikeButton}`, $parent).addClass(cActive);
                } else {
                    $(`.${cDislikeButton}`, $parent).addClass(cActive);
                    $(`.${cLikeButton}`, $parent).removeClass(cActive);
                }

                $(`.${cDislikeButton} ${sNum}`, $parent).html(isSingle ? arrNums[0] : arrNums[1]);
                $(`.${cLikeButton} ${sNum}`, $parent).html(arrNums[0]);

                if (isSingle) {
                    if ($btn.hasClass(cDislikeButton)) {
                        $btn.removeClass(cDislikeButton);
                        $btn.addClass(cLikeButton);
                    } else {
                        $btn.removeClass(cLikeButton);
                        $btn.addClass(cDislikeButton);
                    }
                }
            });
        }
    },
};