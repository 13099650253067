import { Popup } from 'Lib/popup/popup';

const elPopup = document.querySelectorAll('.popup-subscribe');

elPopup.forEach((item) => {
    item.querySelector('.popup-subscribe__btn').addEventListener('click', () => {
        const popup = new Popup('popup', document.querySelector('.popup-subscribe__popup'));

        popup.open();
    });
});
