import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { getNumber } from 'Lib/get-number/get-number';

$('body').on('click', '.js-anchor-link', function (e) {
    e.preventDefault();

    const $this = $(this);
    const href = $this.attr('href');
    const dataHref = $this.attr('data-href');
    const maxSpeed = 750;
    let link = null;

    if (isNoEmptyString(href)) {
        link = href;
    } else if (isNoEmptyString(dataHref)) {
        link = dataHref;
    }

    if (link) {
        link = link.substr(1);

        const dataPlus = getNumber($this.attr('data-plus'));
        const dataMinus = getNumber($this.attr('data-minus'));
        const dataSpeed = getNumber($this.attr('data-speed'));
        const $current = $(`[name=${link}]`).first();

        if ($current.length) {
            const elTop = $current.offset().top + dataPlus - dataMinus;
            let speed = Math.abs($(window).scrollTop() - elTop) / 4;

            if (speed > maxSpeed) {
                speed = maxSpeed;
            }

            if (dataSpeed) {
                speed = dataSpeed;
            }

            $('html, body').animate({ scrollTop: elTop }, speed);
            $this.trigger('eksmoJsAnchorLink', [$current]);
        }
    }
});