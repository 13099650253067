import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import fastdom from 'fastdom';
import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { formatPrice } from 'Lib/format-price/format-price';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import './include/show-back-cover';
import './include/fast-view';

const $body = $('body');
const sBook = '.book';
const sLink = '.book__link';
const sSlider = '.books-slider';
const sLinkWithoutSlider = `.books:not(${sSlider}) ${sLink}`;
const sShield = '.book__shield';
const sImg = '.book__img';
const sName = '.book__name';
const sDeleteBtn = '.book__delete-btn, .book__collection-add-menu-item_delete';

function onLoadImgWithShield($books, callback) {
    onScreen($books.find('.book__img'), ($el) => {
        loadSrcOrBg($el);

        $el.on('eksmoImgLoadSrcOrBg', () => {
            $el.addClass('book__img_loader');
        });
    });

    $books.each(function () {
        const $book = $(this);

        if ($book.find(sShield).length) {
            const $img = $book.find(sImg).first();

            $img.one('eksmoImgLoadSrcOrBg', () => {
                callback($book);
            });
        }
    });
}

function setPositionShield($books) {
    $books.each(function () {
        const $book = $(this);
        const $curShield = $book.find(sShield);
        const $img = $book.find(sImg).first();
        const $curShieldCharity = $book.find('.book__shield_charity-with-sale').first();

        if ($curShield.length && $img.length) {
            fastdom.measure(() => {
                const resultTopOffset = 157 - (($img.height() / $img.width()) * 100);

                if (resultTopOffset > 0) {
                    fastdom.mutate(() => {
                        $curShield.css({ 'margin-top': `${resultTopOffset}%` });
                    });
                }
            });
        }

        if ($curShieldCharity.length) {
            $curShieldCharity.css({ 'top': '35px' });
        }
    });
}

function gaEcommerceView($target) {
    onScreen($target, ($el) => {
        const $book = $el.closest(sBook).first();

        if ($book.length) {
            sendGA4($el.closest(sBook), 'impressions');
        }
    }, 0, 0, 0.8);
}

function sendGA4(elCurrAddedBook, typeEvent) {
    if (elCurrAddedBook) {
        const $book = elCurrAddedBook.closest('.book');

        if ($book.length) {
            const oldPrice = Number(formatPrice($book.find('.book__price-old').text()));
            const price = Number(formatPrice($book.find('.book__price-cur').text()));
            const isPreorder = Number($book.find('.btn-shop').first().text().trim().includes('Предзаказ'));
            const $btnBasket = $book.find('.btn-shop');
            const btnBasketAttr = $btnBasket.attr('data-book24-widget-basket-button-title');
            const textBtnBasket = btnBasketAttr || $btnBasket.attr('data-book24-widget-order-button-title') || $btnBasket.text().trim();
            const objBookInfo = {
                price,
                isPreorder,
                textBtnBasket,
                name: $book.find('.book__name').text().trim(),
                id: $book.attr('data-id'),
                oldPrice,
                discountPrice: oldPrice && price ? oldPrice - price : null,
                brand: $book.attr('data-brand'),
                author: $book.attr('data-author') || $book.find('.text-insert__authors').text().trim(),
                firstCategory: $book.attr('data-first-category'),
                lastCategory: $book.attr('data-last-category'),
                type: $book.find('.book__format').text(),
                list: elCurrAddedBook.closest('[data-ga-ecommerce-name]').attr('data-ga-ecommerce-name') || '',
                index: $book.attr('data-pos'),
            };

            if (objBookInfo.type === 'Электронная книга') {
                objBookInfo.type = 'Электронная';
            } else if (objBookInfo.type === 'Аудиокнига') {
                objBookInfo.type = 'Аудиокнига';
            } else {
                objBookInfo.type = 'Бумажная';
            }

            if (typeEvent === 'wishList') {
                objBookInfo.typeWishList = 'Добавить в избранное';
            } else if (typeEvent === 'wishListCollections') {
                objBookInfo.typeWishList = 'Добавить в подборку';
            }

            eventSender.impressionsClickAddBasketBookGA4(objBookInfo, typeEvent);
        }
    }
}

$body.on('click', '.book__btn-add-collection', function () {
    const $th = $(this);
    const bookId = $th.closest('.book').attr('data-id');
    const collectionId = $th.closest('.books').attr('data-collection-id');

    $body.trigger('collectionAdd.eksmo', [$th, bookId, collectionId]);
    sendGA4($th, 'wishListCollections');
});

$body.on('likeTo.eksmo', (e, el) => sendGA4(el, 'wishList'));
gaEcommerceView($(sLinkWithoutSlider));
onLoadImgWithShield($(sBook), setPositionShield);

$body.on('eksmoPagenavAjax', (e) => {
    gaEcommerceView($(e.target).find(sLinkWithoutSlider));
    onLoadImgWithShield($(e.target).find(sBook), setPositionShield);
});

$(sSlider).on('eksmoBooksSliderPostLoaded', (e) => {
    onLoadImgWithShield($(e.target).find(sBook), setPositionShield);
});

$body.on('addBookBasket.eksmo', (e, data) => {
    const currAddedBook = $(`.book__link .btn-shop[data-book24-widget=${data.productId}]`).first();

    if (currAddedBook.length && !$body.find(`.book-page .price-insert__btn[data-book24-widget=${data.productId}]`).length) {
        sendGA4(currAddedBook, 'addBasket');
    }
});

$('.btn-shop:not(.btn-shop_b24):not(.book__btn-add-collection):not(.book__btn-review)').on('click', function () {
    const $th = $(this);

    if ($th.closest('.book')) {
        sendGA4($th, 'addBasket');
    }
});

$body.on('click', sLink, function (e) {
    const $this = $(this);
    const $book = $this.closest('.book').first();

    if ($this.closest('.owl-item:not(.active)').length) {
        e.preventDefault();
    } else if ($book.length) {
        const sLike = '.book__like';
        const id = $book.attr('data-id');

        if (!$(e.target).closest(sLike).length && !$(e.target).is(sLike)) {
            sendGA4($this, 'click');

            if (id?.length && isLocalStorageEnabled()) {
                localStorage.setItem('EksmoRecommEnkodBookID', id);
            }

            if ($(e.target).closest(sBook).find('.book__format').text() === 'Электронная книга') {
                eventSender.customDL({ 'event': 'buyLitres', 'eventCategory': 'ecommerce', 'eventAction': 'click', 'eventLabel': 'eBook', 'eventStatus': 'success', 'eventNonInteraction': 'false', 'firingOptions': 'oncePerEvent' });
            } else {
                eventSender.customDL({ 'event': 'buyLitres', 'eventCategory': 'ecommerce', 'eventAction': 'click', 'eventLabel': 'aBook', 'eventStatus': 'success', 'eventNonInteraction': 'false', 'firingOptions': 'oncePerEvent' });
            }
        }
    }
});

// Клик на удаление книги (если существуют кнопки удаления)
if ($body.find(sDeleteBtn).length) {
    $body.on('click', sDeleteBtn, function (e) {
        const $this = $(this);

        e.preventDefault();

        $.ajax({
            type: 'POST',
            url: $this.closest('.books').attr('data-delete-url'),
            data: { id: $this.closest('.book').attr('data-id') },
            dataType: 'json',
            success: (data) => {
                if (data.error !== undefined) {
                    $.fancybox.open(`<div><p class="text text_c-gray">Ошибка</p><p>"${data.error}"</p></div>`);
                } else {
                    window.location.reload(true);
                }
            },
            error: () => {
                $.fancybox.open(`
                    <div>
                        <p class="text text_c-gray">Ошибка</p>
                        <p>Попробуйте еще раз или обратитесь в службу технической поддержки.</p>
                    </div>
                `);
            },
        });
    });
}