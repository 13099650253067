import { speed } from 'Lib/speed/speed';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax

const minLengthWord = 3;
const $body = $('body');
const sParent = '.finder';
const cFocused = 'finder_focused';
const sInput = '.finder__input';
const sClose = '.finder__close';
const cCloseShowed = 'finder__close_showed';
const sLoader = '.finder__loader';
const cLoaderShowed = 'finder__loader_showed';
const sResult = '.finder__result';
const cResulted = 'finder_resulted';
const cResultItem = 'finder__result-item';
const sResultItem = `.${cResultItem}`;
const delayBlur = speed;

function blur($parent) {
    if ($parent instanceof jQuery) {
        setTimeout(() => {
            $parent.removeClass(cFocused);
        }, delayBlur);
    }
}

function focus($parent) {
    if ($parent instanceof jQuery) {
        $parent.addClass(cFocused);
    }
}

function clearInput($parent) {
    if ($parent instanceof jQuery && $parent.length) {
        hideResult($parent);
        $parent.find(sClose).removeClass(cCloseShowed);
        $parent.find(sInput).val('');
    }
}

function showResult($parent) {
    if ($parent instanceof jQuery && $parent.length) {
        const $result = $parent.find(sResult);

        if ($result.length) {
            $result.html('');
            $parent.addClass(cResulted);
        }
    }
}

function hideResult($parent) {
    if ($parent instanceof jQuery && $parent.length) {
        const $result = $parent.find(sResult);

        if ($result.length) {
            setTimeout(() => {
                $parent.removeClass(cResulted);
                $result.html('');
            }, delayBlur);
        }
    }
}

$body.on('focus', sInput, function () {
    focus($(this).closest(sParent));
});

$body.on('blur', sInput, function () {
    blur($(this).closest(sParent));
});

$body.on('click', sClose, function () {
    clearInput($(this).closest(sParent));
});

// нажатие на клавиши клавиатуры
$body.on('keyup paste', sInput, function (e) {
    const $this = $(this);
    const val = $.trim($this.val());

    if (
        e.keyCode !== 40
        && e.keyCode !== 38
        && e.keyCode !== 27
        && e.keyCode !== 13
        && e.keyCode !== 16
        && e.keyCode !== 17
        && e.keyCode !== 18
        && e.keyCode !== 19
        && e.keyCode !== 20
        && e.keyCode !== 33
        && e.keyCode !== 34
        && e.keyCode !== 35
        && e.keyCode !== 36
        && e.keyCode !== 37
        && e.keyCode !== 39
        && e.keyCode !== 44
        && e.keyCode !== 45
        && e.keyCode !== 91
        && e.keyCode !== 144
    ) { // кнопки, не обновляющие список такие как alt и shift
        const $parent = $this.closest(sParent);

        if ($parent.length) {
            $parent.find(sLoader).toggleClass(cLoaderShowed, val.length > 2);
            $parent.find(sClose).toggleClass(cCloseShowed, !!val.length);
        }
    }
});

// нажатие на клавиши клавиатуры с задержкой
$body.on('keyup paste', sInput, $.debounce(speed, function (e) {
    const $this = $(this);
    const $parent = $this.closest(sParent);
    const $result = $parent.find(sResult).first();
    const $loader = $parent.find(sLoader);
    const val = $.trim($this.val());

    function hideLoader() {
        $loader.removeClass(cLoaderShowed);
    }

    if (
        e.keyCode !== 40
        && e.keyCode !== 38
        && e.keyCode !== 27
        && e.keyCode !== 13
        && e.keyCode !== 16
        && e.keyCode !== 17
        && e.keyCode !== 18
        && e.keyCode !== 19
        && e.keyCode !== 20
        && e.keyCode !== 33
        && e.keyCode !== 34
        && e.keyCode !== 35
        && e.keyCode !== 36
        && e.keyCode !== 37
        && e.keyCode !== 39
        && e.keyCode !== 44
        && e.keyCode !== 45
        && e.keyCode !== 91
        && e.keyCode !== 144
    ) { // исключая кнопки не обновляющие список такие как alt и shift
        if ($parent.length && $result.length && val.length >= minLengthWord) {
            const href = $parent.attr('data-href');

            if (typeof href === 'string' && href.length) {
                $.ajax({
                    url: href,
                    data: { 'q': val },
                    cache: false,
                    type: 'POST',
                    success(value) {
                        let data = value;
                        let htmlResult = '';

                        if (typeof data !== 'object') {
                            data = $.parseJSON(data);
                        }

                        data.forEach((item) => {
                            const name = item.NAME;

                            if (typeof name === 'string' && name.length) {
                                let strData = '';

                                Object.keys(item).forEach((k) => {
                                    const curVal = item[k];
                                    const propName = k.toLowerCase();

                                    if ((typeof curVal === 'string' && curVal.length) || (typeof curVal === 'number' && !Number.isNaN(curVal))) {
                                        strData += ` data-${propName}="${curVal}"`;
                                    }
                                });

                                htmlResult += `<div class="${cResultItem}"${strData}>${name}</div>`;
                            }
                        });

                        if (htmlResult.length) {
                            showResult($parent);
                            $result.html(htmlResult);
                        }
                    },
                    error() {
                        hideResult($parent);
                    },
                    complete() {
                        hideLoader();
                    },
                });
            } else {
                hideLoader();
            }
        } else {
            hideLoader();
        }
    }
}));

$body.on('click', sResultItem, function () {
    const $this = $(this);
    const $parent = $this.closest(sParent);

    clearInput($parent);
    $parent.trigger('eksmoClickResultItemFinder', [$this.data()]);
});