import { showAuth } from 'Lib/show-auth/show-auth';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';

const sCheckbox = 'input[type=checkbox]';
let $selector = $();
const arID = [];
const arType = [];

export const oReaded = {
    init(selector = false) {
        const that = this;

        if (selector) {
            $selector = $(document).find(selector);

            const $checkbox = $selector.find(sCheckbox);

            if ($('html').hasClass('is-authorized')) {
                this.load();
            } else {
                $('body').on('bitrixDynamicCompositeSuccess.eksmo', () => {
                    if ($('html').hasClass('is-authorized')) {
                        this.load();
                    }
                });
            }

            $checkbox.off('change.readed');

            $checkbox.on('change.readed', function () {
                that.change(this);
            });
        }
    },

    load() {
        if ($selector.length) {
            $selector.each(function () {
                const $this = $(this);
                const dataType = $this.attr('data-type');
                const dataID = $this.attr('data-id');

                if (dataType?.length && !arType.includes(dataType)) {
                    arType.push(dataType);
                }

                if (dataID?.length && !arID.includes(dataID)) {
                    arID.push(dataID);
                }
            });

            if (arType.length && arID.length && !isLocalhost()) {
                $.post('/ajax/like/list/', { type: arType, a_guid: arID }, (data) => {
                    const list = $.parseJSON(data);

                    for (let i = 0; i < list.length; i++) {
                        const dataItem = list[i];

                        $selector.filter(`[data-id=${dataItem?.GUID}]`).find(sCheckbox).prop({
                            'checked': (dataItem?.GUID && dataItem?.SELECTED === 'Y'),
                        });
                    }
                });
            }
        }
    },

    change(sCurrentCheckbox) {
        const $checkbox = $(sCurrentCheckbox);

        if ($checkbox.length) {
            const $el = $checkbox.closest($selector);

            if ($el.length) {
                const dataType = $el.attr('data-type');
                const dataID = $el.attr('data-id');

                if (dataType?.length && dataID?.length) {
                    $.get('/ajax/like/add/', { type: dataType, guid: dataID }, (data) => {
                        if (data.trim() === 'AUTH') {
                            $checkbox.prop({ 'checked': false });
                            showAuth();
                        } else {
                            let dataLike = $.parseJSON(data);

                            dataLike = dataLike[dataID];
                            $checkbox.prop({ 'checked': (dataLike.SELECTED === 'Y') });
                        }
                    });
                }
            }
        }
    },
};