import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax

const $journalArticlesSidebar = $('.journal-articles_sidebar');
const $journalArticlesItem = $('.journal-articles__item');

function checkCheaderFixed() {
    if ($('.cheader_fixed').length) {
        $journalArticlesSidebar.addClass('journal-articles_sidebar-cheader-fixed');
    } else {
        $journalArticlesSidebar.removeClass('journal-articles_sidebar-cheader-fixed');
    }
}

function checkJournalArticlesSidebar() {
    if ($journalArticlesSidebar.length && getW() > eksmoBP.xlMin) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'ab_test',
            eventCategory: 'Главное сегодня - боковое меню',
        });
    } else {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'ab_test',
            eventCategory: 'Главное сегодня - нет блока',
        });
    }
}

onScreen($journalArticlesItem, ($el) => {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'view',
        eventCategory: 'Главное сегодня',
        eventLabel: $el.find('.journal-articles__title').text(),
    });
}, 0, 0, 0.8);

$journalArticlesItem.on('click', function () {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'click',
        eventCategory: 'Главное сегодня',
        eventLabel: $(this).find('.journal-articles__title').text(),
    });
});

checkCheaderFixed();
$(window).on('scroll', $.debounce(speed / 2, checkCheaderFixed));
checkJournalArticlesSidebar();
$('body').on('eksmoResizeWidth', checkJournalArticlesSidebar);