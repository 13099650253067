import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const sLabel = '.switchbox';
const sInput = '.switchbox__input';
const cActive = 'js-active';

$(sLabel).find(`input${sInput}[type=checkbox]`).each(function () {
    if ($(this).is(':checked')) {
        $(this).parents(sLabel).addClass(cActive);
    }
});

$('body').on('change', `${sLabel} input${sInput}[type=checkbox]`, function () {
    const $this = $(this);
    const $label = $this.parents(sLabel);
    const href = $label.attr('data-href');

    $label.toggleClass(cActive, $(this).is(':checked'));

    if (isNoEmptyString(href)) {
        document.location = href;
    }

    $label.trigger('eksmoChangeSwitchbox', [$this.is(':checked')]);
});