import svg4everybody from 'svg4everybody';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $(document.body);
const cSvgSprite = 'js-svg-sprite';

export function svgSpriteLoader(pathSvgSprite, modifierName) {
    if (isNoEmptyString(pathSvgSprite)) {
        // Загрузка SVG-спрайта и все что можно делать только после нее...
        $body.prepend($(`<div class="${cSvgSprite}${
            (isNoEmptyString(modifierName))
                ? ` ${cSvgSprite}_${modifierName}`
                : ''
        }">`).load(window.URLBuild + pathSvgSprite, () => {
            svg4everybody();
        }));
    }
}

export function deleteSvgSprite(modifierName) {
    $body.find(`.${cSvgSprite}${(isNoEmptyString(modifierName)) ? `_${modifierName}` : ''}`).remove();
}