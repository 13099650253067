import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';

const $body = $('body');

export function sendRegDL() {
    onScreen($('.auth-form_reg'), () => {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'authorize',
            eventCategory: 'Создать аккаунт',
            eventLabel: window.location.href,
        });
    });
}

export function showReg(href) {
    if (href.length) {
        $.fancybox.close();

        $.fancybox.open({
            type: 'ajax',
            src: href,
            touch: false,
            afterLoad(instance, current) {
                $body.trigger('eksmoFancyboxAfterLoad', [instance, current]);
                sendRegDL();
                $body.trigger('showReg.eksmo');
            },
        });
    }
}