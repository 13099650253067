import { isObject } from 'Lib/is-object/is-object';

export function getStringGetParams(data) {
    let str = '';

    if (isObject(data)) {
        Object.keys(data).forEach((key) => {
            str += `${!str.length ? '' : '&'}${key}=${data[key]}`;
        });
    } else if (Array.isArray(data)) {
        data.forEach((item) => {
            if (typeof item.name === 'string' && typeof item.value === 'string') {
                str += `${!str.length ? '' : '&'}${item.name}=${item.value}`;
            }
        });
    }

    return str;
}