import { eksmoBP } from 'Lib/bp/bp';
import { onScreen } from 'Lib/on-screen/on-screen';
import { getW } from 'Lib/w/w';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';

const cParentBookV = 'text-insert_book-v';
const $insert = $('.text-insert');
const curWidth = getW();

$insert.each(function () {
    const $this = $(this);
    const $p = $this.closest('p');

    if ($p.length) {
        if (curWidth > eksmoBP.xsMax || ($insert.hasClass(cParentBookV) && curWidth > 559)) {
            $this.prependTo($p);
        } else {
            $this.appendTo($p);
        }
    }
});

if (getW() > eksmoBP.xsMax) {
    onScreen($('.text-insert__pic'), loadSrcOrBg);
}