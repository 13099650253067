import { formatPrice } from 'Lib/format-price/format-price';
import { eventSender } from 'Lib/event-sender/event-sender';
import { onScreen } from 'Lib/on-screen/on-screen';

const sBook = '.text-insert-min';
const $bookLink = $(`${sBook} .text-insert-min__img, ${sBook} .text-insert-min__name`);

function sendGA4(elCurrAddedBook, typeEvent) {
    if (elCurrAddedBook) {
        const $book = elCurrAddedBook.closest('.text-insert-min');

        if ($book.length) {
            const price = Number(formatPrice($book.find('.text-insert-min__price-value').text()));
            const oldPrice = Number(formatPrice($book.find('.text-insert-min__price-old').text()));
            const isPreorder = Number($book.find('.btn-shop').first().text().trim().includes('Предзаказ'));
            const $BtnBasket = $book.find('.text-insert-min__btn');
            const btnBasketAttr = $BtnBasket.attr('data-book24-widget-basket-button-title');
            const textBtnBasket = btnBasketAttr || $BtnBasket.attr('data-book24-widget-order-button-title') || $BtnBasket.text().trim();
            const objBookInfo = {
                price,
                oldPrice,
                isPreorder,
                textBtnBasket,
                name: $book.find('.text-insert-min__name').text().trim(),
                id: $book.attr('data-id'),
                discountPrice: oldPrice && price ? oldPrice - price : null,
                brand: $book.attr('data-brand'),
                type: $book.attr('data-format'),
                firstCategory: $book.attr('data-first-category'),
                lastCategory: $book.attr('data-last-category'),
                author: $book.find('.text-insert-min__authors').text().trim(),
                list: ($book.closest('.author-news').length || $book.closest('.event-detail').length) ? 'новость - врезка' : 'журнал - врезка',
            };

            if (objBookInfo.type === 'Электронная книга') {
                objBookInfo.type = 'Электронная';
            } else if (objBookInfo.type === 'Аудиокнига') {
                objBookInfo.type = 'Аудиокнига';
            } else {
                objBookInfo.type = 'Бумажная';
            }

            eventSender.impressionsClickAddBasketBookGA4(objBookInfo, typeEvent);
        }
    }
}

// События по клику на кнопку "В корзину"/"На Литрес"
$('body').on('addBookBasket.eksmo', (e, data) => {
    const currAddedBook = $(`.text-insert-min__btn[data-book24-widget=${data.productId}]`).first();

    if (currAddedBook.length) {
        sendGA4(currAddedBook, 'addBasket');
    }
});

$('.text-insert-min__btn:not(.btn-shop_b24)').on('click', function () {
    sendGA4($(this), 'addBasket');
});

onScreen($bookLink, ($el) => {
    sendGA4($el.closest(sBook), 'impressions');
}, 0, 0, 0.8);

$bookLink.on('click', function () {
    sendGA4($(this), 'click');
});