import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { getW } from 'Lib/w/w';
import { getNumber } from 'Lib/get-number/get-number';

$.fn.sliderStack = function (o) {
    // Variables
    const $slider = $(this).first();
    const $oldItems = $slider.children();
    let options = {};

    // If exist options
    if (Object.prototype.toString.call(o) === '[object Object]') {
        options = o;
        options.speed = getNumber(options.speed);
        options.lazyLoadImgCalcWidth = getNumber(options.lazyLoadImgCalcWidth);
        options.lazyLoadImgCalcHeight = getNumber(options.lazyLoadImgCalcHeight);
        options.arrowPrev = options.arrowPrev instanceof jQuery && options.arrowPrev.length ? options.arrowPrev.first() : $();
        options.arrowNext = options.arrowNext instanceof jQuery && options.arrowNext.length ? options.arrowNext.first() : $();

        options.lazyLoadImg = options.lazyLoadImg instanceof jQuery && options.lazyLoadImg.length && typeof $slider.find(options.lazyLoadImg)
            ? options.lazyLoadImg
            : $();

        options.loop = typeof options.loop === 'boolean' ? options.loop : false;
    }

    // Initialization
    function init() {
        let $ITEMS = $();
        const $body = $('body');
        let $NUM_ACTIVE_ITEM = 0;
        const cSlider = 'slider-stack';
        const cLoaded = 'slider-stack_loaded';
        const cItem = 'slider-stack__item';
        const sItem = `.${cItem}`;
        const cItemActive = 'slider-stack__item_active';
        const cItemAnimationNext = 'slider-stack__item_animation-next';
        const cItemAnimationPrev = 'slider-stack__item_animation-prev';
        const cIMGLazyLoaded = 'slider-stack__img-lazy-loaded';
        const cArrowDisabled = 'disabled';
        const $IMG = $slider.find('img:not([data-src])');

        function checkN(n) {
            const countItems = $ITEMS.length;
            let num = getNumber(n);

            if (num >= countItems) {
                num = countItems - 1;
            }

            return num;
        }

        function setSlide(n) {
            const oldN = $NUM_ACTIVE_ITEM;
            const num = checkN(n);

            options.arrowPrev.toggleClass(cArrowDisabled, (num === 0));
            options.arrowNext.toggleClass(cArrowDisabled, (num === $ITEMS.length - 1));

            if (num !== oldN) {
                if (oldN < num) { // next
                    $ITEMS.eq(oldN).addClass(cItemAnimationNext);

                    setTimeout(() => {
                        $ITEMS.eq(oldN).removeClass(cItemActive);
                        $ITEMS.eq(oldN).removeClass(cItemAnimationNext);
                        $ITEMS.eq(num).addClass(cItemActive);
                        $slider.trigger('sliderStack.afterSlide');
                    }, options.speed);
                } else { // prev
                    $ITEMS.eq(num).addClass(cItemAnimationPrev);

                    setTimeout(() => {
                        $ITEMS.eq(oldN).removeClass(cItemActive);
                        $ITEMS.eq(num).removeClass(cItemAnimationPrev);
                        $ITEMS.eq(num).addClass(cItemActive);
                        $slider.trigger('sliderStack.afterSlide');
                    }, options.speed / 2);
                }

                $NUM_ACTIVE_ITEM = num;
            }
        }

        function prev() {
            let n = $NUM_ACTIVE_ITEM - 1;

            if (n < 0) {
                if (options.loop) {
                    n = $ITEMS.length - 1;
                } else {
                    return;
                }
            }

            setSlide(n);
        }

        function next() {
            let n = $NUM_ACTIVE_ITEM + 1;

            if (n > $ITEMS.length - 1) {
                if (options.loop) {
                    n = 0;
                } else {
                    return;
                }
            }

            setSlide(n);
        }

        function setHeight() {
            let height = 0;

            $slider.removeAttr('style');
            $slider.removeClass(cLoaded);

            $ITEMS.each(function () {
                const h = $(this).height();

                if (h > height) {
                    height = h;
                }
            });

            $slider.css({ height });

            setTimeout(() => {
                $slider.addClass(cLoaded);
            }, options.speed);
        }

        function setSwipe() {
            let xDown = null;
            let yDown = null;

            function isAllowed() {
                return (getW() < eksmoBP.smMin && $('html').hasClass('touch'));
            }

            function handleTouchStart(e) {
                if (isAllowed()) {
                    xDown = e.touches[0].clientX;
                    yDown = e.touches[0].clientY;
                }
            }

            function handleTouchMove(e) {
                if (!xDown || !yDown || !isAllowed()) {
                    return;
                }

                const xUp = e.touches[0].clientX;
                const yUp = e.touches[0].clientY;
                const xDiff = xDown - xUp;
                const yDiff = yDown - yUp;

                if (Math.abs(xDiff) + Math.abs(yDiff) > 5) {
                    if (Math.abs(xDiff) > Math.abs(yDiff)) {
                        if (xDiff > 0) {
                            next();
                        } else {
                            prev();
                        }

                        e.stopPropagation();
                    }
                }

                xDown = null;
                yDown = null;
            }

            $slider.off('touchstart.sliderStack');
            $slider.off('touchmove.sliderStack');
            $slider.on('touchstart.sliderStack', handleTouchStart);
            $slider.on('touchmove.sliderStack', handleTouchMove);
        }

        function onResize() {
            setHeight();
        }

        function start() {
            let qtyImgLoaded = 0;
            let qtyImgLazyLoaded = 0;

            $slider.addClass(cSlider);

            if (!$oldItems.closest(sItem).length) {
                $oldItems.wrap(`<div class="${cItem}"></div>`);
            }

            $ITEMS = $oldItems.closest(sItem);
            $ITEMS.first().addClass(cItemActive);
            setHeight();

            setTimeout(() => {
                $slider.addClass(cLoaded);

                onScreen($(sItem), ($curSlide) => {
                    loadSrcOrBg($curSlide.find(options.lazyLoadImg));
                    $curSlide.addClass(cIMGLazyLoaded);
                }, options.lazyLoadImgCalcHeight, options.lazyLoadImgCalcWidth);
            }, options.speed);

            options.arrowPrev.on('click.sliderStack', prev);
            options.arrowNext.on('click.sliderStack', next);
            $slider.on('sliderStack.prev', prev);
            $slider.on('sliderStack.next', next);

            setTimeout(() => {
                $body.on('eksmoResizeWidth.sliderStack', $.throttle(speed * 2, onResize));
            }, options.speed);

            $slider.one('sliderStack.destroy', () => {
                $slider.removeClass(`${cSlider} ${cLoaded}`);
                $oldItems.unwrap(sItem);
                options.arrowPrev.off('click.sliderStack');
                options.arrowNext.off('click.sliderStack');
                $body.off('eksmoResizeWidth.sliderStack');
                $slider.off('touchstart.sliderStack');
                $slider.off('touchmove.sliderStack');
            });

            $IMG.each(function () {
                const $img = $(this);
                const img = new Image();

                img.onload = () => {
                    qtyImgLoaded++;

                    if (qtyImgLoaded < $IMG.length) {
                        setHeight();
                    }
                };

                img.src = $img.attr('data-src') || $img.attr('src');
            });

            options.lazyLoadImg.on('eksmoImgLoadSrcOrBg', () => {
                qtyImgLazyLoaded++;

                if (qtyImgLazyLoaded < options.lazyLoadImg.length) {
                    setHeight();
                }
            });

            setSlide();
            setSwipe();
        }

        start();
    }

    // Entry point
    if ($oldItems.length) {
        init();
    }

    return this;
};