import { getW } from 'Lib/w/w';

export function onBpChange(maxLessBp, callback) {
    const isLessScreen = () => getW() <= maxLessBp;
    let lastStateIsLessScreen = isLessScreen();

    $('body').on('eksmoResizeWidth', () => {
        if (isLessScreen() !== lastStateIsLessScreen) {
            callback(isLessScreen());
            lastStateIsLessScreen = isLessScreen();
        }
    });
}