import { eksmoBP } from 'Lib/bp/bp';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { getW } from 'Lib/w/w';
import { speed } from 'Lib/speed/speed';
import 'Vendor/owlcarousel/owl.carousel.min';

const sImgForLoad = '.collections-slider__pic-loader, .collections-slider__image, .collection__pic';
const $slider = $('.collections-slider__slider');
const cSliderOn = 'owl-carousel';
const $pagenavAjax = $('.pagenav-ajax');
let lastStateIsMobileScreen = isMobileScreen();

function loadImg() {
    const $collectionSeries = $(sImgForLoad);

    onScreen($collectionSeries, ($el) => {
        if (!$el.hasClass('collection__series-image_empty')) {
            $el.addClass('collection__image-load');
        }

        loadSrcOrBg($el);
    });
}

function isMobileScreen() {
    return (getW() < eksmoBP.smMin);
}

function setSliders() {
    if (isMobileScreen()) {
        $slider.trigger('destroy.owl.carousel').removeClass(cSliderOn);
    } else {
        $slider.find('.collections-slider__item_arrow').remove();

        $slider.each(function () {
            const $this = $(this);
            const isBigContainer = $this.closest('.container_big');
            let oRes = {
                770: { items: 3, slideBy: 3, margin: 40 },
            };

            if (isBigContainer.length) {
                oRes = {
                    770: { items: 3, slideBy: 3, margin: 40 },
                    1500: { items: 4, slideBy: 4, margin: 40 },
                    1800: { items: 5, slideBy: 5, margin: 40 },
                };
            }

            $this.trigger('destroy.owl.carousel')
                .removeClass(cSliderOn)
                .addClass(cSliderOn)
                .owlCarousel({
                    items: 2,
                    slideBy: 2,
                    smartSpeed: speed,
                    dots: false,
                    nav: true,
                    margin: 40,
                    navClass: ['collections-slider__prev arrow arrow_prev arrow_shadow', 'collections-slider__next arrow arrow_next arrow_shadow'],
                    navText: ['', ''],
                    responsive: oRes,
                });
        });
    }
}

setSliders();

$slider.on('init.collectionsSlider.eksmo', () => {
    setSliders();
});

$('body').on('eksmoResizeWidth', () => {
    if (isMobileScreen() !== lastStateIsMobileScreen) {
        setSliders();
        lastStateIsMobileScreen = isMobileScreen();
    }
});

loadImg();

$pagenavAjax.each(function () {
    $(this).on('eksmoPagenavAjax', loadImg);
});