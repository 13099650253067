import { eksmoBP } from 'Lib/bp/bp';
import { getW } from 'Lib/w/w';

const sMenu = '.fixed-menu';
const $menu = $(sMenu);

function toggleFixedMenu() {
    if (getW() > eksmoBP.xsMax) {
        const $header = $('.cheader');
        const cMenuAnchorLinksFixedCont = 'js-fixed-menu-cont_active';
        const cFixed = 'fixed-menu__content_fix';
        const cNoBottomOffset = 'fixed-menu_bottom-offset';

        $(sMenu).each(function () {
            const $thisMenu = $(this);
            const $content = $thisMenu.closest('.js-fixed-menu-cont').first();
            const $thisMenuContent = $thisMenu.find('.fixed-menu__content').first();

            if ($content.length) {
                const sT = $(document).scrollTop();
                let headerHeight = parseInt($thisMenu.attr('data-offset-top')) || 0;
                const bottomOffset = ($thisMenu.hasClass(cNoBottomOffset)) ? 40 : 0;

                if ($header.length && $thisMenuContent.length) {
                    const $headerFixed = $header.filter('.cheader_fixed');

                    headerHeight += ($headerFixed.length) ? $headerFixed.outerHeight() : $header.outerHeight() - sT;
                }

                if (sT + headerHeight > $thisMenu.offset().top) {
                    $thisMenuContent.width($thisMenu.width()).addClass(cFixed);

                    if (sT + headerHeight + $thisMenuContent.height() + bottomOffset > $content.offset().top + $content.height()) {
                        $content.addClass(cMenuAnchorLinksFixedCont);
                    } else {
                        $content.removeClass(cMenuAnchorLinksFixedCont);
                    }
                } else {
                    $thisMenuContent.removeClass(cFixed).width('');
                }

                $thisMenuContent.css({ 'top': headerHeight, 'max-height': ($(window).height() - headerHeight - bottomOffset) });
            }
        });
    }
}

if ($menu.length) {
    toggleFixedMenu();
    $(window).on('scroll resize', toggleFixedMenu);
    $('body').on('eksmoResizeWidth', toggleFixedMenu);
    $menu.on('eksmoCallFixedMenu', toggleFixedMenu);
}