import { getStringGetParams } from 'Lib/get-string-get-params/get-string-get-params';

export function getUrlByInputs($inputs) {
    const valueByName = [];

    $inputs.each(function () {
        const $input = $(this);
        const type = $input.attr('type');
        const name = $input.attr('name');
        const value = $input.val();

        if (
            typeof type === 'string' && type.length
            && typeof name === 'string' && name.length
            && typeof value === 'string' && value.length
        ) {
            let isCheck = false;

            if (type === 'checkbox' || type === 'radio') {
                isCheck = $input.is(':checked');
            } else if (type === 'text' || type === 'number') {
                isCheck = true;
            }

            if (isCheck) {
                valueByName.push({
                    name,
                    value,
                });
            }
        }
    });

    return getStringGetParams(valueByName);
}