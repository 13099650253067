import { onScreen } from 'Lib/on-screen/on-screen';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { formatPrice } from 'Lib/format-price/format-price';

const $body = $('body');
const $block = $('.book-preview');
const sCont = '.book-preview__cont';
let type;

function sendGA4(elCurrAddedBook, typeEvent) {
    if (elCurrAddedBook) {
        const $book = elCurrAddedBook.closest(sCont);

        if ($book.length) {
            const price = Number(formatPrice($book.find('.price-insert__price').text()));
            const oldPrice = Number(formatPrice($book.find('.price-insert__old-price').text()));
            const isPreorder = $book.find('[data-book24-widget-product-type="preorder"]').length;
            const $btnBasket = $book.find('.price-insert__btn');
            const btnBasketAttr = $btnBasket.attr('data-book24-widget-basket-button-title');
            const textBtnBasket = btnBasketAttr || $btnBasket.attr('data-book24-widget-order-button-title') || $btnBasket.text().trim();
            const objBookInfo = {
                name: $book.find('.book-preview__name').text().trim(),
                id: $book.attr('data-id'),
                isPreorder,
                textBtnBasket,
                price,
                oldPrice,
                discountPrice: oldPrice && price ? oldPrice - price : null,
                brand: $book.attr('data-brand'),
                firstCategory: $book.attr('data-first-category'),
                lastCategory: $book.attr('data-last-category'),
                author: $book.find('.book-preview__authors').text().trim(),
                type: type || 'Бумажная',
                list: elCurrAddedBook.closest('[data-ga-ecommerce-name]').attr('data-ga-ecommerce-name'),
                index: $book.attr('data-pos'),
            };

            eventSender.impressionsClickAddBasketBookGA4(objBookInfo, typeEvent);
        }
    }
}

$body.on('eksmoPagenavAjax', (e) => {
    $body.trigger('init.tabs.eksmo');
    $(e.target).find('.audio-play').trigger('eksmoCallAudioPlaySet');
});

onScreen($block.find(sCont), ($book) => {
    sendGA4($book, 'impressions');
});

$('.book-preview__image-link, .book-preview__name-link').on('click', function () {
    const $book = $(this).closest(sCont);

    if ($book.length) {
        sendGA4($(this), 'click');
    }
});

// События по клику на кнопку "В корзину"/"На Литрес"
$body.on('addBookBasket.eksmo', (e, data) => {
    const currAddedBook = $(`.price-insert__btn[data-book24-widget=${data.productId}]`).first();

    if (currAddedBook.length) {
        sendGA4(currAddedBook, 'addBasket');
    }
});

$block.find('.price-insert__btn').on('click', function () {
    const $th = $(this);

    if ($th.closest('.book-preview').find('.prices-card__tab_el.tabs__link_active').length) {
        type = 'Электронная';
        eventSender.customDL({ 'event': 'buyLitres', 'eventCategory': 'ecommerce', 'eventAction': 'click', 'eventLabel': 'eBook', 'eventStatus': 'success', 'eventNonInteraction': 'false', 'firingOptions': 'oncePerEvent' });
    } else if ($th.closest('.book-preview').find('.prices-card__tab_audio.tabs__link_active').length) {
        type = 'Аудиокнига';
        eventSender.customDL({ 'event': 'buyLitres', 'eventCategory': 'ecommerce', 'eventAction': 'click', 'eventLabel': 'aBook', 'eventStatus': 'success', 'eventNonInteraction': 'false', 'firingOptions': 'oncePerEvent' });
    }
});

$('.price-insert__btn:not(.btn_b24)').on('click', function () {
    sendGA4($(this), 'addBasket');
});

if (getW() > eksmoBP.smMin) {
    $('.book-preview__other-editions-title-box').on('click', function () {
        $(this).closest('.book-preview__other-editions').toggleClass('book-preview__other-editions_open');
    });
}