import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { getW, getH } from 'Lib/w/w';
import { onScreen } from 'Lib/on-screen/on-screen';
import { Popup } from 'Lib/popup/popup';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { inclination } from 'Lib/inclination/inclination';
import 'Vendor/owlcarousel/owl.carousel.min';
import 'Lib/slider-stack/slider-stack';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { eventSender } from 'Lib/event-sender/event-sender';

const $window = $(window);
const $body = $('body');
const $parent = $('.author-page');
const sFixedMenuRight = '.author-page__fixed-menu-right';
const cMenuRightActive = 'author-page__fixed-menu-right_active';
const $linkMainMenu = $('.author-page__link-main-menu');
const cLinkMainMenuActive = 'author-page__link-main-menu_active';
const headerH = 50;
const $menuRightList = $('.author-page__menu-right-list');
const cOwlSliderOn = 'owl-carousel';
const sJsAnchorLink = '.js-anchor-link';
const $MLMMenu = $('.author-page__m-menu-link');
const cMLMMenuActive = 'm-menu__title_active';
const $MLMenuLeft = $('.author-page__menu-left-item');
const cMLMenuLeftActive = 'author-page__menu-left-item_active';
const $MLProfileMenu = $('.author-page__profile-menu-list-item');
const cMLProfileMenuActive = 'author-page__profile-menu-list-item_active';
const $customSection = $('.author-page__c-section');
const $pagenavAjax = $parent.find('.pagenav-ajax');
const $parentFirst = $parent.first();
const isCustom = $parentFirst.hasClass('author-page_custom');
const isCustom3 = $parentFirst.hasClass('author-page_custom-3');
const $sliderVote = $('.author-page__slider-stack-slider_vote');
const $sliderBc = $('.author-page__slider-stack-slider_bc');
const $sliderBcItem = $sliderBc.find('.author-page__bc-item');
const $sliderBcBtn = $sliderBc.find('.author-page__bc-item-btn');
const $sliderReview = $('.author-page__reviews-slider-item');
const sSliderReviewText = '.author-page__review-text';
const sSliderReviewTextCont = '.author-page__review-text-cont';
const sSliderReviewMoreLink = '.author-page__review-more-link';
const cSliderReviewMoreLinkActive = 'author-page__review-more-link_active';
const $cHeadPicsSlider = $('.author-page__c-head-pics-cont');
const sCHeadPicsSliderIMGForLoad = '.author-page__c-head-pics-item';
const $doubleSliders = $('.author-page__reviews-slider, .author-page__contests-slider');
const sDoubleSlidersCont = '.author-page__reviews-slider-cont, .author-page__contests-slider-cont';
const sDoubleSliderIMGForLoad = '.author-page__review-avatar, .author-page__contests-slider-bg';
const $parallax = $('.author-page__parallax').first();
const parallaxOffset = 3;
const popup = new Popup('popup', document.querySelector('.author-page__popup'));

function loadPics($els) {
    onScreen($els, loadSrcOrBg);
}

function toggleMenuRightContent($el, state) {
    if ($el.length) {
        const $fixedMenuRight = $el.closest(sFixedMenuRight);

        if ($fixedMenuRight.length) {
            $fixedMenuRight.toggleClass(cMenuRightActive, (state));
            $fixedMenuRight.find('.fixed-menu').trigger('eksmoCallFixedMenu');
        }
    }
}

function toggleMainMenuFixed() {
    if ($linkMainMenu.length) {
        $linkMainMenu.toggleClass(cLinkMainMenuActive, (getW() < eksmoBP.mdMin && $linkMainMenu.offset().top < $(document).scrollTop() + headerH));
    }
}

function setDoubleSliders() {
    if (getW() < eksmoBP.smMin) {
        $doubleSliders.trigger('destroy.owl.carousel').removeClass(cOwlSliderOn);
        loadPics($doubleSliders.find(sDoubleSliderIMGForLoad));
    } else {
        const $slider = $doubleSliders.find(sDoubleSlidersCont);

        if ($slider.length) {
            const cArrowSmall = isCustom ? '' : ' arrow_small';

            $slider.trigger('destroy.owl.carousel')
                .removeClass(cOwlSliderOn)
                .addClass(cOwlSliderOn)
                .owlCarousel({
                    smartSpeed: speed,
                    dots: false,
                    nav: true,
                    items: 2,
                    slideBy: 2,
                    navClass: [
                        `author-page__arrow-prev arrow${cArrowSmall} arrow_prev arrow_shadow`,
                        `author-page__arrow-next arrow${cArrowSmall} arrow_next arrow_shadow`,
                    ],
                    navText: ['', ''],
                    mouseDrag: false,
                    onInitialized() {
                        loadPics($doubleSliders.find(sDoubleSliderIMGForLoad));
                    },
                });
        }
    }
}

function setCPicsSliders() {
    if ($cHeadPicsSlider.length) {
        if ($cHeadPicsSlider.find(sCHeadPicsSliderIMGForLoad).length > 1) {
            $cHeadPicsSlider.trigger('destroy.owl.carousel')
                .removeClass(cOwlSliderOn)
                .addClass(cOwlSliderOn)
                .owlCarousel({
                    smartSpeed: speed,
                    nav: true,
                    items: 1,
                    slideBy: 1,
                    margin: 120,
                    dotsContainer: '.author-page__c-head-pics-dots.dots',
                    dotClass: 'dots__item',
                    navClass: ['author-page__arrow-prev arrow arrow_prev arrow_no-bg arrow_white', 'author-page__arrow-next arrow arrow_next arrow_no-bg arrow_white'],
                    navText: ['', ''],
                    mouseDrag: false,
                    onInitialized() {
                        loadPics($cHeadPicsSlider.find(sCHeadPicsSliderIMGForLoad));
                    },
                });
        } else {
            loadPics($cHeadPicsSlider.find(sCHeadPicsSliderIMGForLoad));
        }
    }
}

function toggleActiveMenuItem() {
    let name = '';
    const windowH = getH();
    const windowSt = $(window).scrollTop();
    const windowSb = windowSt + windowH;

    $customSection.filter(function () {
        return ($(this).find('a[name]').first().length);
    }).each(function () {
        const $this = $(this);
        const elSt = $this.offset().top;

        if (windowSb > elSt + (windowH * 0.66)) {
            name = $this.find('a[name]').first().attr('name');
        }
    });

    $MLProfileMenu.removeClass(cMLProfileMenuActive);

    if (name.length) {
        $MLProfileMenu.filter(`a[href="#${name}"]`).addClass(cMLProfileMenuActive);
    }
}

function parallax() {
    $parallax.css({ 'transform': `translateY(${Math.round($(document).scrollTop() / parallaxOffset)}px)` });
}

// Блок Персонажи книг
function setSliderPersonagesBook() {
    const $personagesBook = $('.author-page__personages-book');
    const isMainCustomPage = $personagesBook.hasClass('author-page__personages-book_main-custom');

    if (getW() > eksmoBP.mdMin) {
        $personagesBook.each(function () {
            $(this)
                .trigger('destroy.owl.carousel')
                .removeClass(cOwlSliderOn)
                .addClass(cOwlSliderOn)
                .owlCarousel({
                    smartSpeed: speed,
                    nav: true,
                    items: 4,
                    slideBy: 4,
                    margin: isMainCustomPage ? 40 : 24,
                    navClass: ['author-page__arrow-prev arrow arrow_prev arrow_shadow', 'author-page__arrow-next arrow arrow_next arrow_shadow'],
                    navText: ['', ''],
                    responsive: {
                        1600: { items: 5, slideBy: 5 },
                    },
                });
        });
    } else {
        $personagesBook.trigger('destroy.owl.carousel');
    }
}
//

setSliderPersonagesBook();
$body.on('eksmoResizeWidth', setSliderPersonagesBook);

// Для пасхалки на странице Крейн
$('.author-page__link-open-target').on('click', function () {
    window.open($(this).attr('data-href'), '_blank');
});

document.querySelector('.author-page__btn-popup')?.addEventListener('click', () => {
    popup.open();
});
//

if (isCustom) {
    $('.author-page__slider-stack-arrow_prev, .author-page__slider-stack-arrow_next').removeClass('arrow_small');
}

$menuRightList.on('click', function () {
    toggleMenuRightContent($(this), true);
});

$('.author-page__menu-right-toggle').on('click', function () {
    const $fixedMenuRight = $(this).closest(sFixedMenuRight);

    if ($fixedMenuRight.length) {
        toggleMenuRightContent($(this), !$fixedMenuRight.hasClass(cMenuRightActive));
    }
});

$sliderReview.each(function () {
    const $curSlide = $(this);
    const $sliderReviewText = $curSlide.find(sSliderReviewText).first();
    const $sliderReviewMoreLink = $curSlide.find(sSliderReviewMoreLink).first();

    if ($sliderReviewText.length && $sliderReviewMoreLink.length) {
        const $sliderReviewTextCont = $sliderReviewText.find(sSliderReviewTextCont).first();

        if ($sliderReviewTextCont.length && $sliderReviewTextCont.height() > $sliderReviewText.height()) {
            $sliderReviewMoreLink.addClass(cSliderReviewMoreLinkActive);
        }
    }
});

$body.on('bitrixDynamicComposite.eksmo', () => {
    $sliderBc.sliderStack({
        arrowPrev: $sliderBc.parent().find('.author-page__slider-stack-arrow_prev'),
        arrowNext: $sliderBc.parent().find('.author-page__slider-stack-arrow_next'),
        speed: speed * 2,
        lazyLoadImg: $sliderBc.find('.author-page__bc-item, .author-page__bc-item-img'),
    });
});

$sliderBcBtn.on('click', function () {
    $(this).closest($sliderBcItem).find($sliderBcBtn).addClass('btn_no-event');
});

$sliderBc.find('.ajax-form').on('eksmoThisAjaxFormSuccess', function () {
    const sVariant = '.author-page__bc-item-variant';
    const cVariantDisabled = 'author-page__bc-item-variant_disabled';
    const $this = $(this);
    const $variant = $this.closest(sVariant);
    const $curSlider = $this.closest($sliderBc);
    const $curSliderNumberVotes = $curSlider.find('.author-page__bc-item-number-votes-number');
    const $curSliderNumberVotesText = Number($curSlider.find('.author-page__bc-item-number-votes-number').text().split(' ')[0]);

    if ($variant.length) {
        const urlPic = $variant.find('.author-page__bc-item-img').first().attr('src');

        $variant.siblings(sVariant).addClass(cVariantDisabled);

        if (typeof urlPic === 'string' && urlPic.length) {
            eventSender.customDL({ 'event': 'choicePictureBattleCoverOnAuthorPage', 'eventCategory': 'battleCoverOnAuthorPage', 'eventAction': urlPic });
        }
    }

    $curSlider.trigger('sliderStack.next');
    $curSliderNumberVotes.text(`${$curSliderNumberVotesText + 1}  ${inclination($curSliderNumberVotesText + 1, ['голос', 'голоса', 'голосов'])}`);
});

$sliderVote.sliderStack({
    arrowPrev: $sliderVote.parent().find('.author-page__slider-stack-arrow_prev'),
    arrowNext: $sliderVote.parent().find('.author-page__slider-stack-arrow_next'),
    speed: speed * 2,
    lazyLoadImg: $sliderVote.find('.author-page__vote-img'),
});

$('.author-page__vote-form.ajax-form').on('eksmoThisAjaxFormSuccess', function (e, message) {
    const $voteResultNumber = $(this).closest('.author-page__slider-stack-item').find('.author-page__vote-result-number');

    if (typeof message === 'string' && message.length) {
        $(this).find('.author-page__vote-control-text').first().html(message);
    }

    $voteResultNumber.text(Number($voteResultNumber.text()) + 1);

    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'book_card',
        eventCategory: 'Переиздание книги',
        eventLabel: `${$(this).closest('.author-page__vote').find('.author-page__vote-title').text().trim()} - ${$('title').text().trim()}`,
    });
});

$('.author-page__bc-form-file-input').on('change', function (e) {
    const $inputFileCont = $(this).closest('.author-page__bc-form-cont-for-file');
    const $inputFileTextSuccess = $inputFileCont.find('.author-page__bc-form-file-text-success');
    const cInputFileContSuccess = 'author-page__bc-form-cont-for-file_success';

    if (e.target.files[0]) {
        $inputFileTextSuccess.html(`<svg class="author-page__bc-form-file-icon"><use href="#check"></use></svg>${e.target.files[0].name}`);
        $inputFileCont.addClass(cInputFileContSuccess);
    } else {
        $inputFileCont.removeClass(cInputFileContSuccess);
        $inputFileTextSuccess.html('');
    }
});

$MLMMenu.filter(sJsAnchorLink).on('eksmoJsAnchorLink', function () {
    const $this = $(this);

    $this.closest('.js-m-menu-author-menu').trigger('eksmoMMenuClose');
    $MLMMenu.removeClass(cMLMMenuActive);
    $this.addClass(cMLMMenuActive);
});

$MLMenuLeft.filter(sJsAnchorLink).on('eksmoJsAnchorLink', function () {
    $MLMenuLeft.removeClass(cMLMenuLeftActive);
    $(this).addClass(cMLMenuLeftActive);
});

$MLProfileMenu.filter(sJsAnchorLink).on('eksmoJsAnchorLink', function () {
    $MLProfileMenu.removeClass(cMLProfileMenuActive);
    $(this).addClass(cMLProfileMenuActive);
});

toggleMainMenuFixed();
$window.on('scroll resize', toggleMainMenuFixed);
toggleActiveMenuItem();
$window.on('scroll resize', $.debounce(speed, toggleActiveMenuItem));
toggleMenuRightContent($menuRightList, (getW() > eksmoBP.xlMin));

$body.on('eksmoResizeWidth', () => {
    toggleMenuRightContent($menuRightList, (getW() > eksmoBP.xlMin));
});

setDoubleSliders();
$body.on('eksmoResizeWidth', setDoubleSliders);
setCPicsSliders();
$body.on('eksmoResizeWidth', setCPicsSliders);

$pagenavAjax.on('eksmoPagenavAjax', (e) => {
    $(e.target).find('.photo-masonry').trigger('init.photoMasonry.eksmo');
});

onScreen($customSection, ($section) => {
    $section.addClass('author-page__c-section_visible');
});

$('.author-page__chat-form-btn-icon-close-box').on('click', function () {
    $(this).closest('.author-page__chat-form').find('.author-page__chat-form-input').val('').focus();
});

$('.author-page__chat-form_question-answer').on('eksmoThisAjaxFormSuccess', function (e, message, DATA) {
    const $this = $(this);
    const $questionAnswer = $this.closest('.author-page__section, .author-page__c-section').find('.pagenav-ajax__cont');
    let ava = '';

    $questionAnswer.before('<div class="author-page__question-answer-success-signature-box"><svg class="author-page__question-answer-success-icon"><use xlink:href="#hourglass"></use></svg><div class="author-page__question-answer-success-signature">Спасибо! Ваш вопрос отправлен и будет опубликован при условии успешной модерации</div></div><div class="r10"></div><div class="r"></div>');

    if (typeof DATA.DATA.AVA === 'string' && typeof DATA.DATA.NAME === 'string' && typeof DATA.DATA.DATE === 'string' && typeof DATA.DATA.REVIEW === 'string') {
        if (DATA.DATA.AVA.length) {
            ava = `style="background-image:url(${DATA.DATA.AVA})"`;
        }

        $questionAnswer.prepend(
            `${'<div class="question-answer question-answer_moderation">'
                + '<div class="question-answer__ava" '}${ava}></div>`
                + `<div class="question-answer__content">`
                    + `<div class="question-answer__content-main">`
                        + `<div class="question-answer__head-box">`
                            + `<div class="question-answer__head">`
                                + `<div class="question-answer__ava question-answer__ava_m" ${ava}></div>`
                                + `<div class="question-answer__head-content">`
                                    + `<div class="question-answer__name">${DATA.DATA.NAME}</div>`
                                    + `<div class="question-answer__date">${DATA.DATA.DATE}</div>`
                                + `</div>`
                            + `</div>`
                        + `</div>`
                        + `<div class="question-answer__text">${DATA.DATA.REVIEW}</div>`
                    + `</div>`
                + `</div>`
            + `</div>`,
        );
    }
});

$('.author-page__interesting-slider').each(function () {
    $(this).trigger('destroy.owl.carousel')
        .removeClass(cOwlSliderOn)
        .addClass(cOwlSliderOn)
        .owlCarousel({
            smartSpeed: speed,
            nav: true,
            dotsContainer: '.author-page__interesting-dots',
            dotClass: 'dots__item',
            items: 1,
            slideBy: 1,
            navClass: ['author-page__arrow-prev arrow arrow_prev arrow_shadow', 'author-page__arrow-next arrow arrow_next arrow_shadow'],
            navText: ['', ''],
            mouseDrag: false,
            responsive: isCustom3 ? { 770: { items: 2, slideBy: 2, margin: 30 } } : {},
            onInitialized() {
                loadPics($doubleSliders.find(sDoubleSliderIMGForLoad));
            },
        });
});

$window.on('scroll', parallax);

window.onload = function () {
    if (getW() > eksmoBP.lgMax) {
        $parallax.addClass('author-page__parallax_active');
    }
};

