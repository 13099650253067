import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
// import 'Lib/fb-sdk/fb-sdk';

$(document).on('click', '.article-share__item-link', function (e) {
    const $this = $(this);
    const href = $this.attr('href');
    const key = $this.attr('data-key');

    if (href?.length && key?.length) {
        e.preventDefault();
        eventSender.eventSnShare(key);

        if (key === 'fb') {
            /* href = $this.attr('data-href');

            if (href?.length) {
                window.FB.ui({
                    method: 'share',
                    mobile_iframe: true,
                    href: decodeURIComponent(href),
                }, () => {});
            } */
        } else {
            let wwin = getW();

            if (wwin > 560) {
                wwin = 560;
            }

            const mLeft = (window.screen.availWidth / 2) - (wwin / 2);

            window.open(href, 'MsgWindow', `width=${wwin}, height=400, left=${mLeft}, top=50`);
        }
    }
});