import { speed } from 'Lib/speed/speed';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');
const sSpoiler = '.spoiler';
const cInited = 'spoiler_inited';
const cActive = 'spoiler_active';
const cOpened = 'spoiler_opened';
const cDisable = 'spoiler_disable';
const sBlock = '.spoiler__text-block';
const sText = '.spoiler__text';
const sBtn = '.spoiler__btn';

function init($spoilers) {
    $spoilers.not(`.${cInited}`).each(function () {
        const $this = $(this);
        const $textBlock = $this.find(sBlock).first();
        const $text = $this.find(sText).first();
        const dataHeight = $this.attr('data-height');
        let textBlockHeight = $textBlock.outerHeight();

        // Установка высоты спойлера если задана в атрибуте
        if (isNoEmptyString(dataHeight)) {
            $textBlock.height(dataHeight);
            textBlockHeight = dataHeight;
        }

        // Скрываем кнопку если не нужна (может не правильно работать при поздней загрузке шрифта)
        if (textBlockHeight >= $text.outerHeight()) {
            $this.find(sBtn).hide();
            $textBlock.css('height', 'auto');
            $this.addClass(cDisable);
        }

        $this.addClass(cInited);
    });
}

function destroy($spoilers) {
    $spoilers.filter(`.${cInited}`).each(function () {
        const $this = $(this);
        const $textBlock = $this.find(sBlock).first();

        $textBlock.removeAttr('style');
        $this.find(sBtn).first().show();
        $this.removeClass(cDisable);
        $this.removeClass(cInited);
        $this.removeClass(cActive);
        $this.removeClass(cOpened);
    });
}

init($(sSpoiler));

// Кнопка спойлера
$body.on('click', sBtn, function () {
    const $th = $(this);
    const $spoiler = $th.closest('.spoiler');
    const $textBlock = $spoiler.find(sBlock).first();
    const textHeight = $spoiler.find(sText).first().outerHeight();
    const attrHeight = $spoiler.attr('data-height');

    if (!$spoiler.hasClass(cActive)) {
        $spoiler.attr('data-height', $textBlock.outerHeight());
        $textBlock.height(textHeight);
        $spoiler.addClass(cActive);

        setTimeout(() => {
            $spoiler.addClass(cOpened);

            if ($spoiler.hasClass('spoiler_hidden-btn')) {
                $th.remove();
            }
        }, speed);
    } else if (isNoEmptyString(attrHeight)) {
        $spoiler.removeClass(cOpened);
        $textBlock.height(attrHeight);
        $spoiler.removeClass(cActive);
    }
});

$body.on('eksmoResizeWidth', () => {
    $(sSpoiler).each(function () {
        const $this = $(this);
        const attrHeight = $this.attr('data-height');

        if ($this.hasClass(cActive) && isNoEmptyString(attrHeight)) {
            const $textBlock = $this.find(sBlock).first();

            $this.find(sBlock).first().height(attrHeight);
            $this.removeClass(cOpened);
            $textBlock.height(attrHeight);
            $this.removeClass(cActive);
        }
    });
});

$body.on('eksmo.spoiler.init', (e) => {
    const $spoilers = $(e.target);

    if ($spoilers.is(sSpoiler)) {
        destroy($spoilers);
        init($spoilers);
    }
});

$body.on('eksmoPagenavAjax', (e) => {
    const $spoilers = $(e.target).find(sSpoiler);

    destroy($spoilers);
    init($spoilers);
});

$body.on('eksmoFancyboxAfterLoad', (e, instance, current) => {
    const $spoilers = current.$slide.find(sSpoiler);

    destroy($spoilers);
    init($spoilers);
});

$body.on('bitrixDynamicComposite.eksmo', () => {
    const $spoilers = $('body').find(sSpoiler);

    destroy($spoilers);
    init($spoilers);
});

$body.on('toggle.tabsBookPageReviews.eksmo', (e, data) => {
    const $spoilers = data.find(sSpoiler);

    destroy($spoilers);
    init($spoilers);
});