import { showAuth } from 'Lib/show-auth/show-auth';
import { showReg } from 'Lib/show-reg/show-reg';
import { eventSender } from 'Lib/event-sender/event-sender';

const sFormHeader = '.form-bg';
const cFormBgHide = 'form-bg_hide';
const sFormContainer = '.form-bg__box';
const cFormContainerActive = 'form-bg__box_active';
const cReactionsIconInactive = 'form-bg__reactions-icon-box_inactive';
const $formBgForm = $('.form-bg__form');

function reactionsForm() {
    const elReactionsIcons = document.querySelectorAll('.form-bg__reactions-icon-box');

    elReactionsIcons.forEach((item) => {
        item.addEventListener('click', () => {
            document.querySelector('.form-bg__reactions-input').value = item.getAttribute('data-type');

            elReactionsIcons.forEach((itemIcons) => {
                itemIcons.classList.add(cReactionsIconInactive);
            });

            item.classList.remove(cReactionsIconInactive);
        });
    });
}

function checkFormLength(el) {
    const $input = $(el.target).first();

    if ($input.length) {
        const $checker = $input.siblings('.form-bg__form-checker-length');

        if ($checker.length) {
            const $checkerCounter = $checker.find('.form-bg__form-checker-length-count').first();

            if ($checkerCounter.length) {
                const len = 5000;
                let v = $input.val();

                if (v.length > len) {
                    v = v.substring(0, len);
                    $input.val(v);
                }

                $checkerCounter.html(v.length);
            }
        }
    }
}

function toggleForm(e) {
    const $this = $(e.target);
    const $parent = $this.closest(sFormHeader);
    const $cont = $parent.find(sFormContainer);

    if ($parent.length && $cont.length && !$this.hasClass('form-bg__btn-show-form_disabled')) {
        if (e.data.state) {
            $parent.removeClass(cFormBgHide);
            $cont.addClass(cFormContainerActive);
            reactionsForm();
        } else {
            $cont.removeClass(cFormContainerActive);
            $parent.addClass(cFormBgHide);
        }
    }
}

function onClickLinkReopenForm() {
    $('.ajax-form__response').html('');
    $formBgForm.removeClass('ajax-form_success');
}

function setListeners() {
    const $btnShowForm = $('.form-bg__btn-show-form');

    $btnShowForm.on('click', { state: true }, toggleForm);
    $('.form-bg__btn-hide-form').on('click', { state: false }, toggleForm);

    $('.form-bg__link-auth').on('click', (e) => {
        e.preventDefault();
        showAuth();
    });

    $('.form-bg__form-input_check-length').on('keyup', checkFormLength);
    $('.form-bg__link-reopen-form').on('click', onClickLinkReopenForm);
}

$('body').on('bitrixDynamicComposite.eksmo', setListeners);

$('.form-bg_reg').on('click', function (e) {
    e.preventDefault();
    showReg($(this).attr('href'));
});

$formBgForm.on('eksmoThisAjaxFormSuccess', function () {
    const $th = $(this);
    const bookName = $('.book-page__card-title').first().text().trim().replace('Электронная книга ', '').replace('Аудиокнига ', '').replace('Отзывы о книге ', '').replace(/['"«»]/g, '')

    if ($th.hasClass('form-bg__form_reload')) {
        window.location.reload(true);
    }

    if (bookName) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'book_card',
            eventCategory: $th.find('.form-bg__form-btn').val(),
            eventLabel: bookName,
        });
    }
});