import { getUrlByInputs } from 'Lib/get-url-by-inputs/get-url-by-inputs';
import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import { gets, locationSearch } from 'Lib/gets/gets';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

// Ранее применённые фильтры каталога (EKSMORU-1945)

const $menu = $('.menu-left');
const sfilterSubmit = '.menu-left__filter-submit';
const sFilterInput = '.menu-left__filter-input';
const elFilterInputs = document.querySelectorAll(sFilterInput);
const elPreviousFilter = document.querySelector('.js-previous-filters');

if (elPreviousFilter && isLocalStorageEnabled()) {
    let html = '';
    let countPrevious = 0;
    const itemsPreviousRequest = {};
    const previousRequest = JSON?.parse(localStorage?.getItem('EksmoCatalogMenuPreviousFilters'));
    const arrPreviousRequest = previousRequest?.split('&');
    const locationSearchHref = locationSearch ? `?${locationSearch}&` : '?';
    const arrCheckboxesHtmlPage = [];
    const previousRequestText = getW() > eksmoBP.smMin
        ? '<div class="text text_bold">Ранее примененные фильтры:</div>'
        : '<div class="text text_s14">Ранее примененные фильтры:</div>';

    // Сбор инфы о чекбоксах в меню (текст, имя, значение), так как на разных страницах инпуты могут быть разные
    elFilterInputs.forEach((item) => {
        const value = item.value;
        const name = item.getAttribute('name');
        const elCheckboxText = item.closest('.checkbox')?.querySelector('.checkbox__content-offset');
        const text = elCheckboxText ? elCheckboxText.textContent.replace(/[0-9]/g, '') : '';
        const single = item.getAttribute('data-single-filter-url');

        if (text && name && value) {
            arrCheckboxesHtmlPage.push({ text, name, value, single });
        }
    });
    //

    // Обрабатываем полученный из localstorage применённый юзером ранее фильтр
    for (let i = 0; i < arrPreviousRequest?.length; i++) {
        const key = arrPreviousRequest[i].split('=')[0];

        if (!itemsPreviousRequest[key]) {
            itemsPreviousRequest[key] = [];
        }

        itemsPreviousRequest[key].push(arrPreviousRequest[i].split('=')[1]);
    }
    //

    // Проверяем есть ли в урле вхождение для какого-либо фильтра, который открывается на отдельной странице
    // если выбран только он один. Указывается такой параметр в атрибуте "data-single-filter-url"
    let hrefIsSingle = '';

    arrCheckboxesHtmlPage.map((itemCheckboxHtml) => {
        const valueGetParams = `${itemCheckboxHtml.name}=${itemCheckboxHtml.value}`;
        const singleUrl = itemCheckboxHtml.single;

        if (singleUrl && document.location.pathname.includes(singleUrl)) {
            if (hrefIsSingle) {
                hrefIsSingle = `${hrefIsSingle}&${valueGetParams}`;
            } else {
                hrefIsSingle = `${locationSearchHref}${valueGetParams}`;
            }

            return true;
        }

        return false;
    });
    //

    // Перебираем массив инпутов со страницы и объект с ранее используемыми фильтрами, ищем соответствия
    arrCheckboxesHtmlPage.forEach((itemCheckboxHtml) => {
        const singleUrl = itemCheckboxHtml.single;
        const text = itemCheckboxHtml.text;
        let href = '';

        if (!Object.keys(gets).length && singleUrl && !hrefIsSingle) {
            href = singleUrl;
        } else if (hrefIsSingle) {
            href = `${hrefIsSingle}&${itemCheckboxHtml.name}=${itemCheckboxHtml.value}`;
        } else {
            href = `${locationSearchHref}${itemCheckboxHtml.name}=${itemCheckboxHtml.value}`;
        }

        Object.keys(itemsPreviousRequest).forEach((key) => {
            itemsPreviousRequest[key].forEach((itemPrevious) => {
                if (
                    (
                        // если уже применены фильтры, находящиеся также в списке ранее используемых фильтров
                        (Array.isArray(gets[key]) && !gets[key].some((itemGet) => itemGet === itemPrevious))
                        // если уже применен фильтр, находящийся также в списке ранее используемых фильтров
                        || (!Array.isArray(gets[key]) && gets[key] !== itemPrevious)
                    )
                    // поиск соответствия инпутов и ранее используемых фильтров
                    && itemPrevious === itemCheckboxHtml.value && key === itemCheckboxHtml.name
                ) {
                    if (!(singleUrl && document.location.pathname.includes(singleUrl))) {
                        countPrevious++;
                        html += `<a href="${href}" class="btn btn_small filter-row__item">${text}</a>`;
                    }
                }
            });
        });
    });

    if (isNoEmptyString(html)) {
        const btnMore = getW() > eksmoBP.smMin && countPrevious > 6 ? '<div class="colflex__item colflex__item_grow spoiler__btn"><div class="r"></div><span class="a a_orange a_dashed">Еще</span></div>' : '';

        elPreviousFilter.insertAdjacentHTML('afterbegin', `
            <div class="colflex colflex_margin-min${getW() < eksmoBP.xsMax ? ' colflex_wrap' : ''}">
                <div class="colflex__item">
                    <div class="r"></div>
                    ${previousRequestText}
                </div>

                <div class="colflex__item colflex__item_grow">
                    <div class="colflex spoiler spoiler_hidden-btn" data-height="40">
                        <div class="colflex__item colflex__item_grow spoiler__text-block js-colflex-row-list">
                            <div class="filter-row filter-row_scroll filter-row_no-bg">
                                <div class="filter-row__block">
                                    <div class="filter-row__list">${html}</div>
                                </div>

                            </div>
                        </div>
                        ${btnMore}
                    </div>

                </div>
            </div>
            <div class="r20"></div>
        `);

        setTimeout(() => {
            elPreviousFilter.style.display = 'block';
        }, 200);
    }
    //
}

$('body').on('click', sfilterSubmit, () => {
    const urlGet = getUrlByInputs($menu.find(sFilterInput));

    localStorage.setItem('EksmoCatalogMenuPreviousFilters', JSON.stringify(urlGet));
});